import { type ComponentStyleConfig } from "@chakra-ui/react";

export const Heading = (): ComponentStyleConfig => ({
	baseStyle: {
		fontFamily: "heading",
		fontWeight: "normal",
		lineHeight: "shorter",
	},
	sizes: {
		h1: {
			fontSize: ["4xl", null, null, "6xl"],
		},
		h2: {
			fontSize: ["3xl", null, null, "5xl"],
		},
		h3: {
			fontSize: ["2xl", null, null, "4xl"],
			fontWeight: ["light", null, null, "normal"],
		},
		h4: {
			fontSize: ["xl", null, null, "3xl"],
			lineHeight: ["short", null, null, "shorter"],
		},
		h5: {
			fontSize: ["lg", null, null, "xl"],
			fontWeight: "light",
			lineHeight: "short",
		},
		h6: {
			fontSize: ["xmd", null, null, "lg"],
			lineHeight: "tall",
		},
		articleCard: {
			fontSize: ["2xl", null, null, null, "4xl"],
			fontWeight: ["light", null, null, "normal"],
			lineHeight: "tall",
		}
	},
	variants: {},
});
