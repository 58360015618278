import { colors } from "../variables/colors";

export enum ThemeVariant {
	grey, white, themed
}

export enum LogoVariant {
	light = 0, dark = 1
}

export type HighlightsColors = {
	primaryFont: string;
	secondaryFont: string;
	primaryBG: string;
	secondaryBG: string;
	primarySubheaderFont: string;
	secondarySubheaderFont: string;
	primaryDescriptionFont: string;
	secondaryDescriptionFont: string;
	contactCardFontColor?: string;
}

export type ThemeVariantColors = {
	primaryColor: string;
	moduleBackgroundColor: string;
	secondaryBackgroundColor: string;
	specialColor: string;
	headlineColor: string;
	bodyColor: string;
	fontColorPrimary?: string;
	cardBG?: string;
	cardFontColor?: string;
	contactCardFontColor?: string;
	footerCtaColor: string;
	listCardBG: string;
	navigationFontColorPrimary: string;
	highlightsColors: HighlightsColors;
	heroTextColor?: string;
	bannerLinkColor?: string,
}

export type ColorTheme = {
	name: string;
	variants: Record<keyof typeof ThemeVariant, ThemeVariantColors>;
	logo: LogoVariant;
}

export type VariantThemeId = keyof ColorTheme["variants"];

export type GetColorTheme = () => ColorTheme;

export const getDarkBlueColorTheme: GetColorTheme = () => ({
	name: "Dark Blue",
	logo: LogoVariant.light,
	variants: {
		grey: {
			navigationFontColorPrimary: colors.TechBase.light,
			primaryColor: colors.TechPrimary.darkBlue,
			moduleBackgroundColor: colors.TechBase.dividerGrey,
			secondaryBackgroundColor: colors.TechBase.light,
			specialColor: colors.TechPrimary.darkBlue,
			headlineColor: colors.TechPrimary.darkBlue,
			bodyColor: colors.TechBase.dark,
			fontColorPrimary: colors.TechBase.dark,
			cardBG: colors.TechBase.white,
			cardFontColor: colors.TechBase.dark,
			listCardBG: colors.TechBase.light,
			footerCtaColor: colors.TechBase.light,
			bannerLinkColor: colors.TechPrimary.darkBlue,
			contactCardFontColor: colors.TechBase.dark,

			highlightsColors: {
				primaryFont: colors.TechBase.light,
				secondaryFont: colors.TechPrimary.darkBlue,
				primaryBG: colors.TechPrimary.darkBlue,
				secondaryBG: colors.TechBase.light,
				primarySubheaderFont: colors.TechBase.light,
				secondarySubheaderFont: colors.TechPrimary.darkBlue,
				primaryDescriptionFont: colors.TechBase.light,
				secondaryDescriptionFont: colors.TechBase.dark,
				contactCardFontColor: colors.TechPrimary.darkBlue
			}
		},
		white: {
			navigationFontColorPrimary: colors.TechBase.light,
			primaryColor: colors.TechPrimary.darkBlue,
			moduleBackgroundColor: colors.TechBase.light,
			secondaryBackgroundColor: colors.TechBase.grey,
			specialColor: colors.TechPrimary.darkBlue,
			headlineColor: colors.TechPrimary.darkBlue,
			bodyColor: colors.TechBase.dark,
			fontColorPrimary: colors.TechBase.dark,
			cardBG: colors.TechBase.dividerGrey,
			cardFontColor: colors.TechBase.dark,
			listCardBG: colors.TechBase.light,
			footerCtaColor: colors.TechBase.light,
			bannerLinkColor: colors.TechPrimary.darkBlue,
			contactCardFontColor: colors.TechBase.dark,

			highlightsColors: {
				primaryFont: colors.TechBase.light,
				secondaryFont: colors.TechPrimary.darkBlue,
				primaryBG: colors.TechPrimary.darkBlue,
				secondaryBG: colors.TechBase.dividerGrey,
				primarySubheaderFont: colors.TechBase.light,
				secondarySubheaderFont: colors.TechPrimary.darkBlue,
				primaryDescriptionFont: colors.TechBase.light,
				secondaryDescriptionFont: colors.TechBase.dark,
				contactCardFontColor: colors.TechPrimary.darkBlue
			}
		},
		themed: {
			navigationFontColorPrimary: colors.TechBase.light,
			primaryColor: colors.TechPrimary.darkBlue,
			moduleBackgroundColor: colors.TechPrimary.darkBlue,
			secondaryBackgroundColor: colors.TechBase.light,
			specialColor: colors.TechPrimary.green,
			headlineColor: colors.TechBase.light,
			bodyColor: colors.TechBase.light,
			fontColorPrimary: colors.TechBase.light,
			cardBG: colors.TechSpecial.darkBlue,
			cardFontColor: colors.TechBase.light,
			listCardBG: colors.TechBase.light,
			footerCtaColor: colors.TechBase.light,
			bannerLinkColor: colors.TechBase.light,
			contactCardFontColor: colors.TechBase.light,

			highlightsColors: {
				primaryFont: colors.TechBase.light,
				secondaryFont: colors.TechBase.light,
				primaryBG: colors.TechSpecial.darkBlue,
				secondaryBG: colors.TechSpecial.darkBlue,
				primarySubheaderFont: colors.TechBase.light,
				secondarySubheaderFont: colors.TechBase.light,
				primaryDescriptionFont: colors.TechBase.light,
				secondaryDescriptionFont: colors.TechBase.light,
				contactCardFontColor: colors.TechBase.light,
			}
		}
	}
});
