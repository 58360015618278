import { keyFrames } from "./animations";
import { splideStyles } from "./splide";

export const getGlobalStyling = (isRtl: boolean) => ({
	global: {
		...keyFrames(isRtl).keyFrames,
		html: {
			//scrollBehavior: "smooth",
		},
		body: {
			fontFamily: 'body',
			color: 'primary.dark',
			bg: 'primary.grey',//'blackAlpha.50',
			lineHeight: 'base',
			'button, a': {
				'&:focus-visible': {
					outlineStyle: "solid",
					outlineWidth: "1px",
					outlineColor: "primary.aramcoBlue",
					outlineOffset: "-1px"
				},

				"&.primary": {
					".pseudo-arrow, .link-arrow": {
						transitionProperty: "opacity, transform",
						transitionDuration: ".5s",
						transitionTimingFunction: "ease-out"
					},
					".pseudo-arrow": {
						display: ["none", null, null, "block"],
						opacity: 0,
						transform: "translateX(-50%)"
					},
					".link-arrow": {
						transform: "translateX(0)",
					},
					"&:hover": {
						".pseudo-arrow": {
							transform: "translateX(50%)",
							opacity: 1,
							transitionDelay: ".15s"
						},
						".link-arrow": {
							transform: "translateX(100%)",
							opacity: 0,
						},
					}
				},
				"&.primaryCorner": {
					".pseudo-arrow, .link-arrow": {
						transitionProperty: "opacity, transform",
						transitionDuration: ".5s",
						transitionTimingFunction: "ease-out"
					},
					".pseudo-arrow": {
						display: ["none", null, null, "block"],
						opacity: 0,
						transform: "translateX(-50%)"
					},
					".link-arrow": {
						transform: "translateX(0)",
					},
					"&:hover": {
						".pseudo-arrow": {
							transform: "translateX(50%)",
							opacity: 1,
							transitionDelay: ".15s"
						},
						".link-arrow": {
							transform: "translateX(100%)",
							opacity: 0,
						},
					}
				}
			}
		},
		...splideStyles,
		"div.rte-table": {
			overflowX: "auto",

			"thead tr": {
				bgColor: "primary.white"
			},

			"tr": {
				borderBottom: "1px solid",
				borderColor: "primary.greyMedium",
			},

			"td, th": {
				p: "1rem",
				minWidth: ["8rem", null, null, "12rem"],
				fontSize: [".75rem", null, null, ".8125rem"],
				lineHeight: ["1.25", null, null, "1.38"],
				letterSpacing: ["-0.02em", null, null, "-0.03em"],
			},

			"th": {
				textAlign: isRtl ? "right" : "left",
				fontWeight: 750,
			},

			"table": {
				border: "0",
				width: "100%"
			},

			"table thead": {
				border: "none",
				overflow: "hidden",
				padding: "0",
			},

			"table td": {
				marginBottom: 0,
			},
		},

		".chakra-modal__content-container, .chakra-modal__overlay": {
			zIndex: 4
		},

		".chakra-modal__content-container[role='alertdialog'], .chakra-modal__overlay[role='alertdialog']": {
			zIndex: 1000,
		},

		".chakra-portal [data-popper-placement]": {
			zIndex: "11" // Topping the overlay
		},

		".animate-page-wrapper.is-loading": {
			".container, .page-footer": {
				opacity: 0
			},
		},

		".container": {
			position: "relative",
			opacity: 1,
			transitionDuration: `.125s`,
			transitionTimingFunction: "aramcoCubic",
			transitionProperty: "opacity",
			minHeight: "90vh",
		},

		"input[type]": {
			"&:focus, &:focus-within, &:focus-visible": {
				boxShadow: "none !important"
			}
		},

		".LazyLoad": {
			position: "relative",

			"&:not(.is-visible)": {
				"&:after": {
					content: "''",
					position: "absolute",
					height: "100%",
					width: "100%",
					animationName: "skeleton",
					animationIterationCount: "infinite",
					animationTimingFunction: "linear",
					animationDuration: "0.8s",
					animationDirection: "alternate"
				}
			}
		},

		"img.fadeIn": {
			animation: "fadeIn .5s ease-in-out forwards"
		},

	}
});