import React, { type FC, useContext, useState } from "react";
import { type VariantThemeId, type ThemeVariantColors } from "./color-themes/dark-blue-color-theme";
import { getColorThemeVariant } from "./getColorTheme";
import { useSitecoreContext } from "~/foundation/Jss";
import { type AramcoTechProjectDataContext, type ThemePickerBase } from "../../generated-types";
import { type ColorThemeId } from "./themes";

export type ColorThemeContextValue = { name: string } & ThemeVariantColors;

// eslint-disable-next-line
export const ColorThemeContext = React.createContext<[ColorThemeContextValue, React.Dispatch<React.SetStateAction<ColorThemeContextValue>>]>(null as any);

type ColorThemeProviderProps = {
	colorTheme: ColorThemeContextValue;
	children: React.ReactNode;
}

export const ColorThemeProvider: FC<ColorThemeProviderProps> = ({ colorTheme, children }) => {
	const [colorThemeState, setColorTheme] = useState<ColorThemeContextValue>(colorTheme);

	return (
		<ColorThemeContext.Provider value={[colorThemeState, setColorTheme]}>
			{children}
		</ColorThemeContext.Provider>
	);
};

export const useColorTheme = () => {
	const [theme] = useContext(ColorThemeContext);
	if (!theme) {
		throw new Error("useColorTheme must be used within a ColorThemeProvider");
	}
	return theme;
};

export const useSetColorTheme = () => {
	const [, setColorTheme] = useContext(ColorThemeContext);
	if (!setColorTheme) {
		throw new Error("useSetColorTheme must be used within a ColorThemeProvider");
	}
	return setColorTheme;
};

export const useColorThemeVariant = (variant: VariantThemeId) => {
	const { sitecoreContext } = useSitecoreContext<ThemePickerBase, AramcoTechProjectDataContext>();
	const pageTheme = (sitecoreContext?.custom?.projectData.pageTheme || "dark_blue") as ColorThemeId;
	const themeVariant = getColorThemeVariant(pageTheme, variant)
	if (!themeVariant) {
		throw new Error("useColorTheme must be used within a ColorThemeProvider");
	}
	return themeVariant;
};