import { Flex as ChakraFlex, Text, Box } from "@chakra-ui/react";
import { pxToRem } from "../../theme/utils/mapPxToRem";
import { useSitecoreContext } from "~/foundation/Jss";
import { sc } from "../../Jss";
import { type ImageProps } from "./ImageTypes";
import ImageComponent from "./ImageComponent";

const ImageWithCaption = ({ isWrapper = true, caption, height = 0, aspectRatio, children, ...rest }: ImageProps) => {
	const { sitecoreContext } = useSitecoreContext();
	const isRtl = sitecoreContext.custom.settings.isRtl;

	return (
		<>
			<ChakraFlex justifyContent="center" alignItems="center" width="100%" height={height} sx={{
				".LazyLoad": {
					width: "100%",
				}
			}}>
				{aspectRatio ?
					<Box aspectRatio={aspectRatio} w="100%">
						{isWrapper ?
							<ImageComponent {...rest}>
								{children}
							</ImageComponent>
							:
							children
						}
					</Box>
					:
					isWrapper ?
						<ImageComponent {...rest}>
							{children}
						</ImageComponent>
						:
						children
				}
			</ChakraFlex>
			{(caption?.value || sitecoreContext.pageEditing) &&
				<Text
					size="imageCaption"
					mt={pxToRem("12px")}
					display={["none", null, null, "flex"]}
					textAlign={isRtl ? 'right' : 'left'}
				>
					<sc.Text field={caption} />
				</Text>
			}
		</>
	)
};

export default ImageWithCaption;
