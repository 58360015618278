const zIndices = {
	hide: -1,
	auto: "auto",
	base: 0,
	header: 10,
	overHeader: 100,
	level1: 1,
	level2: 2,
	level3: 3,
};

export default zIndices;
