import { text } from "../components/Text";
import { Heading } from "../components/Heading";
import { pxToRem } from "../utils/mapPxToRem";

export const getGlobalStyling = (isRtl: boolean) => {
	return ({
		global: {
			html: {
				//scrollBehavior: "smooth",
				":root": {
					"--vw": "100vw"
				}
			},
			body: {},
			"not('.page-editing')": {
				"*:focus-visible": {
					outline: ".375rem double black !important",
					boxShadow: "0 0 0 .25rem white",
					borderRadius: ".125rem",
				},
			},
			".visually-hidden": {
				clip: "rect(0 0 0 0)",
				clipPath: "inset(50%)",
				height: "1px",
				overflow: "hidden",
				position: "absolute",
				whiteSpace: "nowrap",
				width: "1px",
			},
			".richText": {
				"> div *:last-child": {
					mb: "0",
				},
				"h1,h2,h3,h4,h5,h6,blockquote,p,ul,ol": {
					":first-of-type": {
						mt: "0",
					},
				},
				"h1,h2,h3": {
					...Heading(isRtl).sizes?.headline5,
				},
				"h4,h5,h6": {
					...Heading(isRtl).sizes?.headline6,
				},
				p: {
					...text(isRtl).sizes?.body,
					mb: "1.5rem",
					strong: {
						fontWeight: "600",
					},
					em: {
						fontStyle: "italic",
					},
				},
				a: {
					textDecoration: "underline",
					cursor: "pointer",
					outline: "none",
					wordBreak: "break-word",
					_focusVisible: {
						outline: "1px dotted black",
						outlineOffset: "2px",
						outlineColor: "black",
						textDecoration: "underline dotted",
					},
					_hover: {
						outline: "none",
						textDecoration: "none",
					},
				},
				"ul, ol": {
					...text(isRtl).sizes?.body,
					mb: "1.5rem",
					ml: isRtl ? 0 : pxToRem("30px"),
					mr: isRtl ? pxToRem("30px") : 0,
					"> li": {
						mb: "0.5em",
						strong: {
							fontWeight: "600",
						},
						em: {
							fontStyle: "italic",
						},
					},
				},
				ol: {
					listStyle: isRtl ? "arabic-indic" : "normal",
				},
				strong: {
					fontWeight: "normal",
				},
				em: {
					fontStyle: "normal",
				},

			}
		},
	})
};
