import { useEffect, useState } from "react";
import { Box, Text } from "@chakra-ui/react";
import { Flex } from "../../theme/flex";
import zIndices from "../../theme/variables/z-index";
import { Headline, SubHeading } from "../../components/ui/headline";
import { ComponentContainer } from "../../layouts/component-container";
import { pxToRem } from "../../theme/utils/mapPxToRem";
import { useSitecoreContext } from "~/foundation/Jss";
import ImageWithCaption from "../../components/Image/ImageWithCaption";
import { sc } from "../../Jss";
import { imageSizes } from "../../theme";
import { type Article } from "../../../generated-types";
import { breakpoints } from "../../theme/variables/breakpoints";
import VideoWithCaption from "../../components/Video/VideoWithCaption";
import { type GlobalDictionary } from "../../../dictionary";
import { useTranslation } from "~/foundation/Dictionary";
import { Container } from "../../layouts/container";
import { useColorThemeVariant } from "../../theme/ColorThemeContext";
import { fadeIn } from "../../theme/animations.ts";

type ArticleHeaderContentProps = {
	textWidth?: "100%" | "75%" | "50%";
	isFullWidth: boolean;
	isPeoplePage?: boolean;
	children?: React.ReactNode;
};

export const ArticleHeaderMedia = ({
	isFullWidth,
	isPeoplePage = false,
}: ArticleHeaderContentProps) => {
	const { sitecoreContext } = useSitecoreContext<Article>();
	const isEventArticle = sitecoreContext.route?.templateName?.toLowerCase() === "event article";
	const [t] = useTranslation<GlobalDictionary>();
	const isRtl = sitecoreContext.custom.settings.isRtl;
	const fields = sitecoreContext.route?.fields;
	const itemId = sitecoreContext.route?.itemId;
	const [isVideoOverlay, setIsVideoOverlay] = useState(false);

	useEffect(() => {
		if (window?.innerWidth < 992) {
			setIsVideoOverlay(true);
		}
	}, []);

	return (
		fields && <Box position="relative"
			{...!isEventArticle ? {
				flex: ["0 0 auto", null, null, isFullWidth ? "1" : "0 0 auto"],
				transform: [isRtl ? "translateX(50%)" : "translateX(-50%)", null, null, isFullWidth ? null : "translateX(0)"],
				ml: [!isRtl ? "50%" : 0, null, null, isFullWidth ? null : 0],
				mr: [isRtl ? "50%" : 0, null, null, isFullWidth ? null : 0],
				mt: ["calc(-5rem - 35px)", "calc(-5rem - 60px)", null, isFullWidth ? "calc(-5rem - 100px)" : "calc(-5rem - 130px)", isFullWidth ? "calc(-5rem - 140px)" : "calc(-5rem - 130px)"],
				zIndex: [0, null, null, isFullWidth ? null : zIndices.overHeader],
				width: ["var(--vw)", null, null, isFullWidth ? "100vw" : 'calc(100% * 671 / 1184)'],
				animation: `${fadeIn} .5s ease-in-out`,
			} : {
				flex: 1,
			}}>
			{sitecoreContext.pageEditing &&
				<Text as="p" size="body" color="white" p="2px 5px" bg="black" position="absolute" {...isRtl ? { right: "10px" } : { left: "10px" }} zIndex="5" pointerEvents="none"
					{...isEventArticle ?
						{
							top: "10px",
						}
						:
						{
							top: ["240px", null, null, isFullWidth ? "330px" : "420px"],
						}
					}>
					{fields.vimeoId?.value ? t("general.labels.videoImageLabel") : t("general.labels.articleImageLabel")}
				</Text>
			}
			{fields.vimeoId?.value ?
				<>
					{sitecoreContext.pageEditing ?
						<ImageWithCaption
							caption={!isFullWidth ? fields.caption : undefined}
							isImgSrc={!!(fields.videoImage?.value?.src || fields.image?.value?.src)}
							{...isEventArticle ?
								{
									aspectRatio: 1184 / 593,
									height: "auto",
								}
								:
								{
									height: ["250px", null, null, isFullWidth ? "370px" : "460px"],
									isShadow: true,
								}
							}
						>
							<sc.AdvanceImage
								fields={fields}
								itemId={itemId}
								fieldName="videoImage"
								sizes={`(min-width: ${breakpoints.lg}) 992px, 100vw`}
								lazy={false}
								{...isEventArticle ?
									{
										srcSet: [imageSizes.fullImage.lg, imageSizes.fullImage.xl],
									}
									:
									{
										srcSet: [imageSizes.articleHeader.sm, isFullWidth ? imageSizes.articleHeader.lg.fullWidth : imageSizes.articleHeader.lg.halfWidth],
									}
								}
								sx={{
									objectFit: "cover",
									objectPosition: "center center",
									width: "100%",
									height: "100%",
									...!fields.videoImage?.value?.src && sitecoreContext.pageEditing ? { position: "absolute", top: 0, bottom: 0, left: 0, right: 0, zIndex: 1 } : {},
								}}
							/>
						</ImageWithCaption>
						:
						<VideoWithCaption caption={fields?.caption} isImgSrc={!!(fields?.videoImage?.value?.src || fields.image?.value?.src)} videoId={fields?.vimeoId} videoTitle={fields?.videoTitle} isAspectRatio={isEventArticle} isOverlay={isFullWidth ? true : isVideoOverlay} isFullWidth={isFullWidth}
							{...isEventArticle ?
								{
									aspectRatio: 1184 / 593,
									height: "auto",
								} : {
									isShadow: true,
								}
							}>
							<sc.AdvanceImage
								fields={fields}
								itemId={itemId}
								fieldName={fields?.videoImage?.value?.src ? "videoImage" : "image"}
								{...isEventArticle ?
									{
										srcSet: [imageSizes.fullImage.lg, imageSizes.fullImage.xl],
									}
									:
									{
										srcSet: [imageSizes.articleHeader.sm, isFullWidth ? imageSizes.articleHeader.lg.fullWidth : imageSizes.articleHeader.lg.halfWidth],
									}
								}
								sizes="100vw"
								lazy={true}
								sx={{
									objectFit: "cover",
									objectPosition: "center center",
									width: "100%",
									height: "100%",
								}}
							/>
						</VideoWithCaption>
					}
				</>
				:
				<>
					<ImageWithCaption
						caption={!isFullWidth ? fields.caption : undefined}
						isImgSrc={!!fields.image?.value?.src}
						{...isEventArticle ?
							{
								aspectRatio: 1184 / 593,
								height: "auto",
							}
							:
							{
								height: [isPeoplePage ? "450px" : "250px", null, null, isFullWidth ? "370px" : "460px"],
								isShadow: true,
							}
						}
					>
						<sc.AdvanceImage
							fields={fields}
							itemId={itemId}
							fieldName="image"
							sizes="100vw"
							lazy={true}
							{...isEventArticle ?
								{
									srcSet: [imageSizes.fullImage.sm,imageSizes.fullImage.md,imageSizes.fullImage.lg],
								}
								: isPeoplePage ?
									{
										//peoplepage does not have the full-width variant from sitecore
										srcSet: [imageSizes.peoplePageHeader.sm, imageSizes.peoplePageHeader.md]
									}
									:
									{
										srcSet: isFullWidth ? [imageSizes.articleHeader.sm.fullWidth,imageSizes.articleHeader.md.fullWidth,imageSizes.articleHeader.lg.fullWidth] : [imageSizes.articleHeader.sm, imageSizes.articleHeader.md],
									}
							}
							sx={{
								objectFit: "cover",
								objectPosition: "center center",
								width: "100%",
								height: "100%",
								...!fields.image?.value?.src && sitecoreContext.pageEditing ? { position: "absolute", top: 0, bottom: 0, left: 0, right: 0, zIndex: 1 } : {},
							}}
						/>
					</ImageWithCaption>
				</>
			}
			{sitecoreContext.pageEditing &&
				<>
					<Flex mt={pxToRem("10px")}>
						<Text as="p" size="body" mb={pxToRem("10px")} color="black" flex="0 0 auto">
							{t("general.labels.videoIdLabel")}
						</Text>
						<Text as="p" size="body" border="1px solid black" mb={pxToRem("10px")} color="black" flex="1">
							<sc.Text field={fields.vimeoId} />
						</Text>
					</Flex>
				</>
			}
		</Box>
	)
}



export const ArticleHeader = ({
	textWidth = "100%",
	isFullWidth,
	children,
}: ArticleHeaderContentProps) => {
	const { sitecoreContext } = useSitecoreContext<Article>();
	const isEventArticle = sitecoreContext.route?.templateName?.toLowerCase() === "event article";
	const fields = sitecoreContext.route?.fields;
	const { moduleBackgroundColor, primaryColor } = useColorThemeVariant("white");

	return (
		fields && <ComponentContainer divider="none" innerBottomPadding={pxToRem('40px')} useOverflowX={false} customBgColor={moduleBackgroundColor}>
			<Flex gap={["48px", null, null, "52px"]}
				{...isEventArticle ?
					{
						flexDir: "column-reverse",
					}
					:
					{
						flexDir: ["column", null, null, !isFullWidth ? "row-reverse" : "column"],
						justifyContent: "flex-end",
					}
				}>
				{(fields?.image?.value?.src || fields?.videoImage?.value?.src || sitecoreContext.pageEditing) &&
					<>
						{isEventArticle ?
							<Container display="flex" w="100%" mb={[pxToRem("32px"), null, null, pxToRem("40px")]}>
								<ArticleHeaderMedia textWidth={textWidth} isFullWidth={isFullWidth} />
							</Container>
							:
							<ArticleHeaderMedia textWidth={textWidth} isFullWidth={isFullWidth} />
						}
					</>
				}
				<Box flex={["1", null, null, (isFullWidth && textWidth !== "100%") || isEventArticle || (!fields?.image?.value?.src && !fields?.videoImage?.value?.src) ? "0 0 auto" : "1"]} width={["100%", null, null, isFullWidth || isEventArticle || (!fields?.image?.value?.src && !fields?.videoImage?.value?.src) ? textWidth : null]}>
					{children}
					{(fields?.title?.value || sitecoreContext.pageEditing) && <Headline as="h2" size="articleHeaderHeading" mt={children ? pxToRem("17px") : 0} color={primaryColor}>
						<sc.Text field={fields.title} />
					</Headline>}
					{fields.topicTags && <Flex gap={pxToRem("8px")} mt={pxToRem("16px")} flexWrap="wrap">
						{fields.topicTags.map((tag, index) => (
							<Text key={index} size="tag" variant="tag">{tag.displayName}</Text>
						))}
					</Flex>}
					{(fields.teaser?.value || sitecoreContext.pageEditing) && <SubHeading as="h3" size="articleHeaderSubheading" mt={pxToRem("32px")} color="TechBase.dark">
						<sc.Text field={fields.teaser} />
					</SubHeading>}
				</Box>
			</Flex>
		</ComponentContainer>
	)
};
