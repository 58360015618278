/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { type FC } from "react";
import { Link as ChakraLink, type LinkProps as ChakraLinkProps } from "@chakra-ui/react";
import {
	Link as RouterLink,
	type LinkProps as RouterLinkProps,
} from "react-router-dom";
import { pushDataLayer } from "~/foundation/Tracking/GoogleTagManager";

export type LinkProps = ChakraLinkProps & {
	ref?: React.RefObject<HTMLAnchorElement>;
	to?: RouterLinkProps["to"];
	size?: string;
	variant?: string;
	ariaLabel?: string;
	linkLabel?: string;
	target?: string;
	className?: string;
	rel?: string;
	children?: RouterLinkProps["children"];
	download?: boolean;
	dataLayer?: () => GTMDataLayer;
};

export const Link: FC<LinkProps> = ({
	ref,
	to,
	size,
	variant,
	ariaLabel,
	target,
	className,
	rel,
	children,
	download,
	dataLayer,
	...rest
}) => {
	const anchorRef = ref ?? React.createRef<HTMLAnchorElement>();

	return (
		<ChakraLink
			{...!download ?
				{
					as: (to ? RouterLink : "span"),
					to,
				}
				:
				{
					as: RouterLink,
					to,
				}
			}
			size={size}
			variant={variant}
			aria-label={ariaLabel}
			target={target}
			className={`${className ?? ''} ${variant ?? ''}`}
			rel={rel}
			ref={anchorRef}
			{...download && { download }}
			{...(dataLayer && {
				onClick: () => {
					pushDataLayer(dataLayer);
				},
			})}
			{...rest}
		>
			{children}
		</ChakraLink>
	);
};
