import { getDefaultColorTheme } from "./color-themes/default-color-theme";
import { getAmethystPurpleColorTheme } from "./color-themes/amethyst-purple-color-theme";
import { getBurgundyColorTheme } from "./color-themes/burgundy-color-theme";
import { getDarkTealColorTheme } from "./color-themes/dark-teal-color-theme";
import { getLightBlueColorTheme } from "./color-themes/light-blue-color-theme";
import { getOrangeColorTheme } from "./color-themes/orange-color-theme";
import { getDarkGreenColorTheme } from "./color-themes/dark-green-color-theme";
import { getGoldenYellowColorTheme } from "./color-themes/golden-yellow-color-theme";

export const themes = {
	"default": getDefaultColorTheme,
	"light_blue": getLightBlueColorTheme,
	"orange": getOrangeColorTheme,
	"amethyst_purple": getAmethystPurpleColorTheme,
	"burgundy": getBurgundyColorTheme,
	"dark_teal": getDarkTealColorTheme,
	"dark_green": getDarkGreenColorTheme,
	"golden_yellow": getGoldenYellowColorTheme,
};

export const defaultThemeName = Object.keys(themes)[0] as ColorThemeId;

export type ColorThemeId = keyof typeof themes;