import { useEffect, useRef, type FC } from "react";
import { Box } from "@chakra-ui/react";
import { type PageBase } from "~/foundation/Multisite/generated-types";
import { Breadcrumbs } from "../../../_Base/components/breadcrumbs";
import { Headline, SubHeading } from "../../../_Base/components/ui/headline";
import { useSitecoreContext, type ImageField } from "~/foundation/Jss";
import { ComponentContainer } from "../../layouts/component-container";
import { sc } from "../../Jss";
import { useColorThemeVariant } from "../../theme/ColorThemeContext";
import { type PageHeader } from "../../../generated-types";
import "../../css/global.css";
import { imageSizes } from "../../theme";
import { motion } from "framer-motion";

export type PageHeaderProps = PageBase & {
	description?: {
		value: string,
	},
	image?: ImageField,
	headline: {
		value: string,
	}
}

const PageHeader: FC<PageHeaderProps> = () => {
	const { headlineColor, moduleBackgroundColor, fontColorPrimary } = useColorThemeVariant("white");
	const { sitecoreContext } = useSitecoreContext<PageHeader>();
	const fields = sitecoreContext.route?.fields;
	const description = fields?.description?.value;
	const breadcrumbItems = sitecoreContext?.custom?.breadcrumbItems?.items || [];
	const fixed = useRef<HTMLDivElement>(null);
	const MotionBox = motion(Box);

	useEffect(() => {
		if (fixed.current === null) {
			return;
		}

		const handleScroll = () => {
			const scrollValue = window.scrollY;

			// Adjust the value to control the intensity of the translation effect
			const translateValue = Math.min(scrollValue * 0.4, 400);

			if (fixed.current && fixed.current.style) {
				fixed.current.style.transform = `translateY(${translateValue}px)`;
			}
		};

		window.addEventListener('scroll', handleScroll);

		return () => {
			window.removeEventListener('scroll', handleScroll);
		};
	}, [fixed]);

	return (
		<MotionBox
			as="section"
			bgColor={"#fff"}
			transition={{ duration: 0.5, effect: "easeInOut" }}
			data-rendering="SectionHeader">
			{fields?.image?.value?.src ? <Box
				width={'full'}
				pos={'relative'}
				top="-279px"
				marginBottom="-279px"
				ref={fixed}
				height={{
					base: "430px",
					lg: "590px",
				}}
			>
				<sc.AdvanceImage
					fields={fields}
					itemId={sitecoreContext.route?.itemId}
					fieldName="image"
					srcSet={[imageSizes.parallax.md, imageSizes.parallax.lg, imageSizes.parallax.xl]}
					sizes={"100vw"}
					lazy={false}
					sx={{
						objectFit: "cover",
						objectPosition: "center",
						minWidth: "100%",
						minHeight: "100%",
					}}
				/>
			</Box> : null}
			<ComponentContainer
				divider="bottom"
				innerBottomPadding={".2rem"}
				themed={false}
				customBgColor={moduleBackgroundColor}
				useOverflowX={false}
			>
				{breadcrumbItems.length ? <Box
					mb={"1.5rem"}
				>
					{<Breadcrumbs items={breadcrumbItems} />}
				</Box> : null}
				<Box
					w="full"
					pos={"relative"}
				>
					<Box w={["80%", null, null, "70%"]} pos={"relative"}>
						{fields?.headline?.value && <Headline size="headline1" as="h1" color={headlineColor}>
							{fields?.headline?.value}
						</Headline>}
						{(description && description !== "") &&
							<SubHeading
								size="subheader"
								fontWeight={600}
								mt={"2rem"}
								color={fontColorPrimary}
							>
								<Box dangerouslySetInnerHTML={description ? { __html: description } : undefined} as="span" />
							</SubHeading>}
					</Box>
					<Box id="page-header-portal" />
				</Box>

			</ComponentContainer>
		</MotionBox>
	);
};

export default PageHeader;

