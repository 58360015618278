import { type FC } from "react";
import { type Tag, type TopicCategoryPage, type TopicCategoryHeaderProps } from "../../generated-types";
import { type ImageField, type TextField, useSitecoreContext } from "~/foundation/Jss";
import { PageHeader } from "../../_Base/Components/PageHeader";
import { getColorTheme } from "../../_Base/Theme/getColorTheme";
import { imageSizes, type ColorThemeId } from "../../_Base/Theme";
import FeaturedCard, { type FeaturedCardProps } from "../../_Base/Components/Cards/featuredCard";
import { type AuthorInCardInfoProps } from "../../_Base/Components/Cards/cardInfo";
import { type TopicTag } from "../../_Base/Components/UI/Tag";
import { useColorTheme } from "../../_Base/Theme/ColorThemeContext";
import ModuleInViewAnimationContainer from "../../_Base/Components/UI/moduleInViewAnimationContainer";
import { Flex } from "@chakra-ui/react";
import { Chip } from "../../_Base/Components/UI/Chip";
import ImageSkeletonContainer from "../../_Base/Components/UI/imageSkeletonContainer";
import { LazyImage } from "~/foundation/Components/Image/LazyImage";
import { useTranslation } from "~/foundation/Dictionary";
import { type GlobalDictionary } from "../../dictionary";

export type TopicFeaturedContentProps = TopicCategoryHeaderProps & {
	theme?: ColorThemeId;
}

export const TopicFeaturedContent: FC<TopicFeaturedContentProps> = ({ rendering, theme }) => {
	const { sitecoreContext } = useSitecoreContext<TopicCategoryPage>();
	const fields = sitecoreContext.route?.fields;
	const articleReference = fields?.articleReference;
	const category = fields?.category?.fields as Tag;
	const categoryTags = (articleReference?.fields.categoryTags as TopicTag[]).length > 0 ? (articleReference?.fields.categoryTags as TopicTag[]) : undefined;
	const TopicColorTheme = category && !theme ? getColorTheme(category.theme?.value as ColorThemeId) : theme ? getColorTheme(theme) : undefined;
	const { isDarkBg, colors: { primaryColor, cardTextColor } } = useColorTheme();
	const [t] = useTranslation<GlobalDictionary>();

	const renderedFields: FeaturedCardProps = {
		isDarkBg: TopicColorTheme ? TopicColorTheme.isDarkBg : isDarkBg,
		cardTextColor: TopicColorTheme ? TopicColorTheme.colors.cardTextColor : cardTextColor,
		primaryColor: TopicColorTheme ? TopicColorTheme.colors.primaryColor : primaryColor,
		id: rendering.dataSource ?? "",
		url: articleReference?.url,
		urlTitle: (articleReference?.fields.title as TextField)?.value,
		imageDirection: "right",
		title: articleReference?.fields.title as TextField,
		description: (fields?.description?.value ? fields?.description : articleReference?.fields?.teaser) as TextField,
		trumpet: (fields?.trumpet?.value ? fields?.trumpet : articleReference?.fields?.trumpet) as TextField,
		topics: categoryTags?.map(tag => (
			{
				id: tag.id,
				name: tag.name,
				title: tag.fields.title.value ?? "",
				theme: tag.fields.theme.value ?? "",
			}
		)),
		authors: (articleReference?.fields?.contributors as AuthorInCardInfoProps[])?.map(contributor => (
			{
				id: contributor.id,
				name: contributor.fields?.fullName?.value ?? "",
				url: contributor.url ?? "",
			}
		)),
		date: articleReference?.fields?.date ? (articleReference.fields.date as TextField)?.value : undefined,
		articleLength: (articleReference?.fields.readTime as TextField)?.value ? Number((articleReference?.fields.readTime as TextField)?.value) : undefined,
		articleIssueTitle: (articleReference?.fields?.issueReference as unknown as { fields: { title: TextField } })?.fields?.title?.value,
		cardSpacing: "lg",
		aspectRatio: [350 / 233, null, null, 905 / 603],
		isImageCover: true,
		overrides: {
			trumpet: !!fields?.trumpet?.value,
			title: !!fields?.headline?.value,
			description: !!fields?.description?.value,
		}
	}
	return (
		<section>
			<ModuleInViewAnimationContainer>
				<FeaturedCard
					isDarkBg={renderedFields.isDarkBg}
					cardTextColor={renderedFields.cardTextColor}
					primaryColor={renderedFields.primaryColor}
					id={renderedFields.id}
					url={renderedFields.url}
					imageDirection={renderedFields.imageDirection}
					title={renderedFields.title}
					description={renderedFields.description}
					trumpet={renderedFields.trumpet}
					topics={renderedFields.topics}
					authors={renderedFields.authors}
					date={renderedFields.date}
					articleLength={renderedFields.articleLength}
					articleIssueTitle={renderedFields.articleIssueTitle}
					cardSpacing={renderedFields.cardSpacing}
					aspectRatio={renderedFields.aspectRatio}
					isImageCover={renderedFields.isImageCover}
					overrides={renderedFields.overrides}
					image={(articleReference?.fields?.image as ImageField)?.value?.src ?
						<ImageSkeletonContainer
							advancedImageProps={{
								fields: articleReference?.fields,
								itemId: sitecoreContext.itemId,
								fieldName: "image",
								alt: (articleReference?.fields?.image as ImageField)?.value?.alt ?? renderedFields.title ?? '',
								srcSet: [imageSizes.default.sm, imageSizes.default.md, imageSizes.default.lg, imageSizes.default.xl],
								sizes: "100%",
								lazy: true,
								sx: {
									objectFit: "cover",
									objectPosition: "center center",
									width: "100%",
									height: "100%",
								},
							}}
						/>
						:
						<ImageSkeletonContainer>
							<LazyImage src={(articleReference?.fields?.image as ImageField)?.value?.src} alt={(articleReference?.fields?.title as TextField)?.value} />
						</ImageSkeletonContainer>
					}
					buttons={
						<Flex mt={["lmd", null, null, "xl"]} gap="md">
							<Chip size={"md"} variant={renderedFields.isDarkBg ? "light" : "dark"}
								_groupHover={{
									bg: "transparent",
									color: renderedFields.isDarkBg ? "primary.white" : "primary.dark",
								}}>{t('button.readmore.readMoreText')}</Chip>
						</Flex>
					}
				/>
			</ModuleInViewAnimationContainer>
		</section>
	)
}

const TopicCategoryHeader: FC<TopicCategoryHeaderProps> = ({ rendering }) => {
	const { sitecoreContext } = useSitecoreContext<TopicCategoryPage>();
	const fields = sitecoreContext.route?.fields;
	const headline = fields?.headline?.value;
	const trumpet = fields?.trumpet?.value;
	const breadcrumbItems = sitecoreContext?.custom?.breadcrumbItems?.items || [];
	const menuItems = rendering.customData?.menuItems;
	const category = fields?.category?.fields as Tag;
	const theme = category.theme?.value as ColorThemeId;
	const { colors: { primaryColor } } = getColorTheme(theme);

	return (
		<>
			<section data-rendering="TopicCategoryHeader">
				<PageHeader
					headline={headline || ''}
					trumpet={trumpet}
					breadcrumbItems={breadcrumbItems}
					menuItems={menuItems}
					bgColor={primaryColor}
					theme={theme}
				/>
			</section>
			{fields?.articleReference ? <section>
				<TopicFeaturedContent rendering={rendering} />
			</section> : null}
		</>
	);
};

export default TopicCategoryHeader;