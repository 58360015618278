import { useState, useEffect } from "react";
import { Box, Flex, Button, List, ListItem, Text } from "@chakra-ui/react";
import { type GlobalDictionary } from "../../dictionary";
import { useTranslation } from "~/foundation/Dictionary";
import BundleIcon from "./bundleIcon";
import { Chip } from "./UI/Chip";
import { useSitecoreContext } from "~/foundation/Jss";

export type sitecoreContextType = {
	custom: {
		projectData?: {
			shareNames?: string[];
		};
	};
};

type ShareBannerProps = {
	componentName?: string,
	isDarkBgTheme?: boolean,
}

const ShareIconsList = ({ componentName = 'share-button', isDarkBgTheme = false }: ShareBannerProps) => {
	const [documentURL, setDocumentURL] = useState("");
	const pageUrl = encodeURIComponent(documentURL);
	const POPUP_SIZE = 570;
	const { sitecoreContext } = useSitecoreContext();
	const projectData = (sitecoreContext as sitecoreContextType).custom.projectData;
	const socialMediaList = projectData?.shareNames ?? [];

	const socialWindow = (url: string) => {
		const left = (screen.width - POPUP_SIZE) / 2;
		const top = (screen.height - POPUP_SIZE) / 2;
		const params = `menubar=no,toolbar=no,status=no,width=570,height=570,top=${top},left=${left}`;
		window.open(url, "NewWindow", params);
	};

	const SocialMediaIcons: { [key: string]: { icon: 'x' | 'whatsapp' | 'facebook', url: string } } = {
		Twitter: {
			icon: "x",
			url: `https://twitter.com/intent/tweet?url=${pageUrl}`
		},
		Whatsapp: {
			icon: "whatsapp",
			url: `https://wa.me/?text=${pageUrl}`
		},
		Facebook: {
			icon: "facebook",
			url: `https://www.facebook.com/sharer/sharer.php?u=${pageUrl}`
		}
	};

	const getIconColor = () => {
		if (componentName === 'share-button') {
			return isDarkBgTheme ? "primary.black" : "primary.white";
		}
		return isDarkBgTheme ? "primary.white" : "primary.black";
	}


	useEffect(() => {
		setDocumentURL(document.URL);
	}, []);

	return (
		socialMediaList.map((item: string) => {
			return (
				<ListItem key={`share_social_media_item_${item.toLowerCase()}`} p="xxs">
					<Button
						variant="footerSocial"
						title={item}
						display="block"
						p="xxs"
						transition="all 0.2s ease"
						_hover={{
							bg: !isDarkBgTheme ? "primary.white" : "primary.black",

							svg: {
								fill: !isDarkBgTheme ? "primary.black" : "primary.white"
							}
						}}
						onClick={() => socialWindow(SocialMediaIcons[item].url)}
						{...SocialMediaIcons[item].icon === "whatsapp" && { dataAction: "share/whatsapp/share" }}
					>

						{item && <BundleIcon
							name={SocialMediaIcons[item].icon}
							fill={getIconColor()}
							size="lg"
							transition="all 0.2s ease"
						/>}
					</Button>
				</ListItem>
			)
		})

	)
}

export const ShareButton = ({ isDarkBgTheme = false }: ShareBannerProps) => {
	const [t] = useTranslation<GlobalDictionary>();
	const { sitecoreContext } = useSitecoreContext();
	const projectData = (sitecoreContext as sitecoreContextType).custom.projectData;
	const socialMediaList = projectData?.shareNames ?? [];

	return (
		socialMediaList && socialMediaList.length > 0 && <Box position="relative" data-group="share-button">
			<Chip size={"md"} variant={isDarkBgTheme ? "bordered-light" : "bordered-dark"} cursor="pointer">{t('socialMedia.shareLabel')}</Chip>
			<Box as="nav" aria-label={t('socialMedia.shareSocialMediaList')}
				flex="0 0 auto"
				pt="sm"
				position="absolute"
				top="100%"
				left="50%"
				zIndex="hide"
				opacity="0"
				visibility="hidden"
				pointerEvents="none"
				transform="translateX(-50%) translateY(10px)"
				transition="all 0.2s ease-in, z-index 0s 0.2s"
				_groupHover={{
					opacity: 1,
					visibility: "visible",
					zIndex: "level1",
					pointerEvents: "auto",
					transform: "translateX(-50%) translateY(0)",
					transition: "all 0.2s ease-out, z-index 0s",
				}}
			>
				<List display="flex"
					alignItems="center"
					bg={isDarkBgTheme ? "primary.white" : "primary.black"}
					position="relative"
					boxShadow="0px 0px 10px 0px rgba(0, 0, 0, 0.2)"
					_before={{
						content: '""',
						width: 0,
						height: 0,
						borderLeft: "10px solid transparent",
						borderRight: "10px solid transparent",
						borderBottom: "10px solid",
						borderBottomColor: isDarkBgTheme ? "primary.white" : "primary.black",
						position: "absolute",
						top: 0,
						left: "50%",
						transform: "translateX(-50%) translateY(-100%)",
					}}
				>
					<ShareIconsList componentName="share-button" isDarkBgTheme={isDarkBgTheme} />
				</List>
			</Box>
		</Box>
	)
}

export const ShareBanner = ({ isDarkBgTheme = false }: ShareBannerProps) => {
	const [t] = useTranslation<GlobalDictionary>();
	const { sitecoreContext } = useSitecoreContext();
	const projectData = (sitecoreContext as sitecoreContextType).custom.projectData;
	const socialMediaList = projectData?.shareNames ?? [];

	return (
		socialMediaList && socialMediaList.length > 0 && <Flex gap="md" pt={["md", null, null, "lg"]} borderTop="1px solid" borderColor={isDarkBgTheme ? "tints.white.50" : "tints.dark.50"} alignItems="center">
			<Text size="shareLabel" flex="1" color={isDarkBgTheme ? "tints.white.50" : "tints.dark.50"}>{t('socialMedia.shareLabel')}</Text>
			<Box as="nav" aria-label={t('socialMedia.shareSocialMediaList')} flex="0 0 auto">
				<List display="flex" alignItems="center">
					<ShareIconsList componentName="share-banner" isDarkBgTheme={isDarkBgTheme} />
				</List>
			</Box>
		</Flex>
	)
}
