import { type ComponentStyleConfig } from "@chakra-ui/react";
import { pxToRem } from "../../Utils/mapPxToRem";

export const Icon = (): ComponentStyleConfig => ({
	// The styles all Headings have in common
	baseStyle: {
		boxSize: pxToRem("16px"),
	},
	sizes: {
		lg: {
			boxSize: pxToRem("24px"),
		},
		sxl: {
			boxSize: pxToRem("32px"),
		},
		xl: {
			boxSize: pxToRem("48px"),
		},
		xxl: {
			boxSize: pxToRem("64px"),
		},
		logo: {
			boxSize: pxToRem("16px"),
		}
	},
});
