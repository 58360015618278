import { useSitecoreContext } from "~/foundation/Jss";
import { ColorThemeProvider } from "./ColorThemeContext";
import { getColorTheme } from "./getColorTheme";
import { type AramcoQafilahProjectDataContext, type ThemePicker, type ThemePickerRenderingParameters } from "../../generated-types";
import { defaultThemeName, type ColorThemeId } from "./themes";

export type WithThemeProps = {
	params: ThemePickerRenderingParameters;
}

export function themable() {
	return function themable<P extends WithThemeProps>(InputComponent: React.FC<P>) {

		const Themable = (props: P) => {
			const { sitecoreContext } = useSitecoreContext<ThemePicker, AramcoQafilahProjectDataContext>();
			const pageTheme = (sitecoreContext?.custom.projectData?.pageTheme || defaultThemeName) as ColorThemeId;
			const moduleTheme = (props.params.theme || defaultThemeName) as ColorThemeId | undefined;
			const theme: ColorThemeId = moduleTheme || pageTheme;

			const colorTheme = getColorTheme(theme);
			if (colorTheme) {
				return (
					<ColorThemeProvider colorTheme={colorTheme}>
						<InputComponent {...props} />
					</ColorThemeProvider>
				);
			}

			return <InputComponent {...props} />
		}

		Themable.displayName = "Themable";

		return Themable;
	};
}