import { mapPxToRem } from "../../Utils/mapPxToRem";

const breakpoints = mapPxToRem({
	base: "0px",
	sm: "390px",
	md: "769px",
	lg: "993px",
	xl: "1441px",
});

export default breakpoints;