import { createMultiStyleConfigHelpers } from "@chakra-ui/react";
import { selectAnatomy } from '@chakra-ui/anatomy'
import { baseStyle as inputBaseStyle } from "./Input";

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(selectAnatomy.keys);

const baseStyle = definePartsStyle({
	...inputBaseStyle,
});

const lightTheme = definePartsStyle({
	field: {
		borderBottom: "1px solid white",
	}
})

export const Select = defineMultiStyleConfig({
	baseStyle: {
		...baseStyle,
		field: {
			...baseStyle.field,
		}
	},
	variants: {
		light: lightTheme
	}
})
