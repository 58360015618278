/* eslint-disable @typescript-eslint/no-unused-vars */
import { type ComponentStyleConfig } from "@chakra-ui/react";

export const Link = (rtl = true): ComponentStyleConfig => ({
	baseStyle: {
		fontFamily: "body",
		display: "inline-flex",

		_focusVisible: {
			outline: ".375rem double black",
			outlineOffset: "1px",
			boxShadow: "0 0 0 .25rem white",
			borderRadius: ".125rem",

			_before: {
				outline: "none",
				boxShadow: "none",
			},
		},
	},
	sizes: {
		linkS: {
			fontSize: "xs",
			lineHeight: "base",
		},
		footerSubscribeLink: {
			fontSize: ["2xl", null, null, "4xl"],
			lineHeight: "shorter"
		},
		smallBodyBold: {},
		bannerLink: {},
		moduleLink: {},
	},
	variants: {
		fullWidth: {
			width: "100%",
		},
		footerSocial: {},
		footerLink: {
			transition: "all 0.3s ease-in-out",
			_hover: {
				color: "secondary.goldenYellow",
			}
		},
		moduleLink: {},
	},
});
