import { AspectRatio, Box, Flex, Heading, List, ListItem, Text, type ResponsiveValue } from "@chakra-ui/react";
import { formatDate } from "../../Utils/formatDate";
import CardInfo from "./cardInfo";
import { ChipIcon } from "../UI/Chip";
import { Tag } from "../UI/Tag"
import { LazyImage } from "~/foundation/Components/Image/LazyImage";
import { pxToRem } from "../../Utils/mapPxToRem";
import { formatTime } from "../../Utils/formatTime";
import { Link } from "../Link";
import { transparentize } from "polished";
import { getColorTheme } from "../../Theme/getColorTheme";
import { type ColorThemeId } from "../../Theme/themes";
import BundleIcon from "../bundleIcon";


export type CardTopicProps = {
	id: string;
	title: string;
	theme: string;
}

export type CardAuthorProps = {
	id: string;
	fullName: string;
}

type CardContentProps = {
	id: string;
	title?: string;
	titleTag?: "h2" | "h3";
	teaser?: string;
	topics?: CardTopicProps[];
	isTopicColored?: boolean;
	issueTitle?: string;
	authors?: CardAuthorProps[];
	date?: Date | string;
	articleLength?: string | number;
	isDarkBgTheme: boolean;
	issueMonth?: string;
}

type CardVariant = "default" | "search"

type CardSharedProps = CardContentProps & {
	image?: {
		url: string;
		alt: string;
	};
	aspectRatio?: ResponsiveValue<number>;
	hoverColor?: string;
	isMobileCards?: boolean;
	variant?: CardVariant;
}

type CardProps = CardSharedProps & {
	url?: string;
	type?: string;
}

export const getAspectRatio = (index: number, isSlider: boolean = false) => {
	if (!isSlider) {
		if (index === 0 || (index + 1) % 6 === 0 || index % 6 === 0) return 3 / 2;
	}
	if (index % 3 === 0 && isSlider) return 3 / 2;
	if (index - 1 === 0 || (index + 2) % 6 === 0 || (index - 1) % 6 === 0) return 16 / 9;
	return 9 / 8;
};

const CardContent = ({ id, title, titleTag, teaser, topics, authors, date, articleLength, isDarkBgTheme, issueTitle, isTopicColored = true }: CardContentProps) => {
	const getTagVariant = () => {
		if (isTopicColored) return "colored";
		return isDarkBgTheme ? "light" : "dark";
	}

	const getTagTheme = (theme?: ColorThemeId) => {
		const { colors: { tags } } = getColorTheme(theme);
		return tags;
	}

	return (
		<>
			{topics && topics.length > 0 && <List display="flex" flexWrap="wrap" gap="10px" mb="md" aria-label="Article Tags">
				{topics?.map((topic) => (
					topic.title && <ListItem key={`${id}_${topic.id}`}>
						<Tag
							variant={getTagVariant()}
							display="inline-flex"
							alignItems="center"
							{...isTopicColored && {
								bg: getTagTheme(topic.theme as ColorThemeId).backgroundColor,
								color: getTagTheme(topic.theme as ColorThemeId).color,
							}}
						>{topic.title}</Tag>
					</ListItem>
				))}
			</List>}
			{title && <Heading
				size="articleCard"
				as={titleTag}
				color={isDarkBgTheme ? 'primary.white' : 'primary.black'}
				display={"-webkit-box"}
				sx={{
					WebkitLineClamp: 2,
					WebkitBoxOrient: "vertical",
				}}
				overflow={"hidden"}
			>{title}</Heading>}
			{teaser && <Text display={"-webkit-box"}
				mt={["xs", null, null, "sm"]}
				sx={{
					WebkitLineClamp: 2,
					WebkitBoxOrient: "vertical",
				}}
				overflow={"hidden"}>{teaser}</Text>}
			{(authors || date || articleLength || issueTitle) && <Box mt={["xs", null, null, "sm"]}>
				<CardInfo id={id} params={[issueTitle ?? undefined, ...(authors?.map(author => author.fullName) ?? []), date ? formatDate(date) : undefined, articleLength ? formatTime(Number(articleLength)) : undefined]} isDarkBgTheme={isDarkBgTheme} />
			</Box>}
		</>
	)
}

const PodcastCard = ({ id, title, titleTag = "h3", image, topics, authors, date, aspectRatio = 1, articleLength, isDarkBgTheme = true, issueTitle }: CardSharedProps) => {
	return (
		<AspectRatio ratio={1}>
			<Box position="relative">
				<AspectRatio
					ratio={aspectRatio}
					position="absolute"
					top={0}
					left={0}
					right={0}
					bottom={0}
					zIndex="level1"
					overflow="hidden"
					_after={{
						content: '""',
						position: "absolute",
						top: 0,
						left: 0,
						width: "100%",
						bottom: 0,
						zIndex: "level2",
						opacity: 0.7,
						background: "overlays.podcastCard",
						transition: "all 0.3s ease-in-out",
						_groupHover: {
							opacity: 0.8,
						}
					}}
				>
					<LazyImage src={image?.url} alt={image?.alt && image?.alt !== "" ? image.alt : title} />
				</AspectRatio>
				<Flex
					flexDir="column"
					justifyContent="space-between"
					position="relative"
					zIndex="level3"
					p={["md", null, null, null, "lmd"]}
					w="100%"
					h="100%">
					<Flex flexDir="column" flex={1}>
						<CardContent
							id={id}
							title={title}
							titleTag={titleTag}
							topics={topics}
							authors={authors}
							date={date}
							articleLength={articleLength}
							isDarkBgTheme={isDarkBgTheme}
							issueTitle={issueTitle}
						/>
					</Flex>
					<Flex mt={["xs", null, null, "sm"]}>
						<ChipIcon icon="play" size={"md"} variant={isDarkBgTheme ? "light" : "dark"} flex="0 0 auto"
							_groupHover={{
								bg: "transparent",
								color: isDarkBgTheme ? "primary.white" : "primary.dark",
							}}
						/>
					</Flex>
				</Flex>
			</Box>
		</AspectRatio>
	)
}

const IssueCard = ({ id, title, image, date, aspectRatio = 1, isDarkBgTheme = true, issueMonth }: CardSharedProps) => {
	return (
		<Flex
			flexDir="column"
			gap={["sm", null, null, "md"]}
			p={["xs", null, null, "md"]}
			mx={["-xs", null, null, "-md"]}
			transition="background-color 0.3s ease-in-out"
			_groupHover={{
				bg: "tints.dark.10",
			}}
		>
			<Box
				position="relative"
				flex="1"
				overflow="clip"
				w="100%"
				h="100%"
			>
				{image && image.url !== "" &&
					<AspectRatio
						className="article-card-ratio"
						ratio={aspectRatio}
						position="relative"
						sx={{
							img: {
								objectFit: "contain",
							}
						}}
					>
						<LazyImage src={image?.url} alt={image?.alt ?? title} />
					</AspectRatio>}
			</Box>
			<Flex flexDir="column" justifyContent="space-between">
				<Flex flexDir="column" flex={1}>
					{(issueMonth || date) && <Box>
						<CardInfo
							id={id}
							params={[issueMonth ?? undefined, date ? new Date(date).getFullYear() : undefined]}
							isDarkBgTheme={isDarkBgTheme}
						/>
					</Box>}
					{title && <Heading
						size="articleCard"
						as="h3"
						mt="xs"
						color={isDarkBgTheme ? 'primary.white' : 'primary.black'}
						display={"-webkit-box"}
						sx={{
							WebkitLineClamp: 2,
							WebkitBoxOrient: "vertical",
						}}
						overflow={"hidden"}
					>{title}</Heading>}
				</Flex>
			</Flex>
		</Flex>
	)
}

const ArticleCard = ({ variant = "default", id, title, titleTag = "h3", teaser, image, topics, authors, date, aspectRatio = 3 / 2, hoverColor = "transparent", articleLength, isDarkBgTheme = false, isMobileCards = true, isTopicColored = true, issueTitle }: CardSharedProps) => {
	return (
		<Flex
			flexDir={isMobileCards ? ["row", null, null, (variant === "search" ? "row" : "column")] : "column"}
			gap={["sm", null, null, "lmd"]}>
			<Box
				position="relative"
				flex={isMobileCards ? ["0 0 auto", null, null, (variant === "default" ? "1" : "0 0 auto")] : 1}
				overflow="clip"
				w={isMobileCards ? [pxToRem("130px"), null, null, (variant === "default" ? "100%" : "300px")] : "100%"}
				//background="white"
				h="100%"

				_before={{
					display: ["none", null, null, "block"],
					content: `""`,
					position: "absolute",
					top: 0,
					left: 0,
					width: "150%",
					height: "150%",
					backgroundColor: transparentize(0.15, hoverColor),
					backgroundImage: "radial-gradient(circle at center, black 1px, transparent 0), radial-gradient(circle at center, black 1px, transparent 0)",
					backgroundSize: "6px 6px",
					backgroundPosition: "0 0, 3px 3px",
					opacity: 0,
					zIndex: 'level3',
					transform: "rotate(-10deg) translate(-25%, -25%)",
					mixBlendMode: "multiply",
					transition: "opacity 0.2s ease-in-out",

					_groupHover: {
						opacity: 1
					}
				}}
				sx={{
					"img,.article-card-image-placeholder": {
						zIndex: 1,
					},
					".article-card-ratio": {
						_before: {
							background: `url(${image?.url})`,
							backgroundSize: "cover",
							backgroundPosition: "center",
							zIndex: "level2",
							position: "relative",
							filter: ["none", null, null, "grayscale(100%) brightness(1.2) contrast(1.5) saturate(0.3)"],
							opacity: 0,
							transition: "opacity 0.2s ease-in-out",

							_groupHover: {
								opacity: 1
							}
						}
					}
				}}
			>
				{image && image.url !== "" ?
					<AspectRatio
						className="article-card-ratio"
						ratio={aspectRatio}
						position="relative"
					>
						<LazyImage src={image?.url} alt={image?.alt ?? title} />
					</AspectRatio>
					:
					<AspectRatio className="article-card-ratio" ratio={aspectRatio} position="relative" bg="primary.white">
						<Box display="flex" justifyContent="center" alignItems="center" bg="tints.black.5" className="article-card-image-placeholder">
							<Box width="50%" maxW="200px">
								<BundleIcon name="qafilah-logo" w="100%" h="100%" fill="tints.black.10" />
							</Box>
						</Box>
					</AspectRatio>
				}
			</Box>
			<Box>
				<CardContent
					id={id}
					title={title}
					titleTag={titleTag}
					teaser={teaser}
					topics={topics}
					authors={authors}
					date={date}
					articleLength={articleLength}
					isDarkBgTheme={isDarkBgTheme}
					isTopicColored={isTopicColored}
					issueTitle={issueTitle}
				/>
			</Box>
		</Flex>
	)
}

const Card = ({ id, type = "article", url, title, ...rest }: CardProps) => {
	return (
		<Link
			to={url}
			title={title}
			display="block"
			data-group="card"
		>
			{type === "article" && <ArticleCard id={id} title={title} {...rest} />}
			{type === "podcast" && <PodcastCard id={id} title={title} {...rest} />}
			{type === "issue" && <IssueCard id={id} title={title} {...rest} />}
		</Link>
	)
}

export default Card;