import { type ColorThemeId, defaultThemeName, themes } from "./themes";

export const getColorTheme = (colorThemeId: ColorThemeId | undefined) => {
	const theme = themes[colorThemeId ?? defaultThemeName];
	if (theme) {
		return theme();
	}

	return themes.dark_green();
}
