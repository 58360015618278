import { colors } from "../variables/colors";
import { type ColorTheme } from "./color-theme";

export const getGoldenYellowColorTheme = (): ColorTheme => ({
	name: "Golden Yellow",
	isDarkBg: false,
	colors: {
		primaryColor: colors.secondary.goldenYellow,
		dark: "#0C2340",
		light: "#F5F5F5",
		cardTextColor: colors.primary.dark,
		tags: {
			backgroundColor: colors.tags.artsBg,
			color: colors.tags.artsText,
		},
		quoteTextColor: colors.secondary.goldenYellow,
	}
});