import React, { type FC } from "react";
import { Text, type TextField, useSitecoreContext } from "@sitecore-jss/sitecore-jss-react"; // eslint-disable-line no-restricted-imports
import { type TextProps as SitecoreTextProps } from '@sitecore-jss/sitecore-jss-react/types/components/Text';

export type TextProps = Omit<SitecoreTextProps, "field"> & {
	field?: TextField;
	tag?: React.ElementType
}

// To avoid deprecation warning...
// eslint-disable-next-line @typescript-eslint/no-explicit-any
(Text as any).defaultProps = undefined;

const TextWrapper: FC<TextProps> = ({
	editable = true,
	encode = true,
	...props
}) => {
	const { sitecoreContext } = useSitecoreContext();

	if (!props?.field && !sitecoreContext.pageEditing) {
		return <></>;
	}

	const newProps = { editable, encode, ...props };

	return <Text {...(newProps as SitecoreTextProps)} />;
};

export default TextWrapper;