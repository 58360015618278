/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { type FC, type ComponentProps } from "react";
import { Placeholder } from "@sitecore-jss/sitecore-jss-react"; // eslint-disable-line no-restricted-imports
import { type ComponentRendering } from "@sitecore-jss/sitecore-jss/layout";

type PlaceholderWrapperProps = ComponentProps<typeof Placeholder> & {
	customReactKey?: (placeholderData: ComponentRendering[], rendering: ComponentRendering, index: number) => string;
	appendKey?: string;
}

const PlaceholderWrapper: FC<PlaceholderWrapperProps> = (props): any => {
	let customReactKey = props.customReactKey;
	if (!customReactKey) {
		customReactKey = (placeholderData, rendering, index) => {
			const key = rendering.uid
				? `${rendering.uid}-${index}`
				: `component-${index}`;

			return key;
		}
	}

	return <Placeholder {...props} customReactKey={customReactKey} />;
};

export default PlaceholderWrapper;