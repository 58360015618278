import { type ResponsiveValue, AspectRatio, Box, Container, Flex, Heading, List, ListItem, Text } from "@chakra-ui/react";
import { Link } from "../Link";
import { Tag } from "../UI/Tag";
import { formatDate } from "../../Utils/formatDate";
import CardInfo from "./cardInfo";
import { type TextField, type FileField, useSitecoreContext } from "~/foundation/Jss";
import { sc } from "../../Jss";
import { formatTime } from "../../Utils/formatTime";

type FeaturedCardContent = {
	isDarkBg?: boolean;
	cardTextColor?: string;
	id: string;
	type?: "article" | "issue";
	image?: React.ReactNode;
	imageDirection?: "left" | "right";
	aspectRatio?: ResponsiveValue<number>;
	isImageCover?: boolean;
	trumpet?: TextField;
	title?: TextField;
	description?: TextField;
	topics?: {
		id: string;
		name: string;
		title: string;
		theme: string;
	}[];
	authors?: {
		id?: string;
		name: string,
		url: string,
	}[];
	date?: Date | string;
	articleLength?: number;
	issueNumber?: string;
	issueMonth?: string;
	cardSpacing?: "lg" | "xxl";
	buttons?: React.ReactNode;
	overrides?: {
		trumpet?: boolean;
		title?: boolean;
		description?: boolean;
	}
	issueFile?: FileField;
	articleIssueTitle?: string;
}

export type FeaturedCardProps = FeaturedCardContent & {
	url?: string;
	urlTitle?: string;
	primaryColor?: string;
}

const FeaturedCardContent = ({ isDarkBg = true, cardTextColor, id, type = "article", image, imageDirection = "right", aspectRatio, isImageCover = true, trumpet, title, description, topics, authors, date, articleLength, issueNumber, issueMonth, buttons, overrides, articleIssueTitle, ...rest }: FeaturedCardContent) => {
	const { sitecoreContext } = useSitecoreContext();

	return (
		<Flex flex="1" gap="lmd" flexDir={["column", null, null, imageDirection === "right" ? "row" : "row-reverse"]} {...rest}>
			{trumpet && type !== "issue" && <Text size="cardTrumpet" color={cardTextColor} display={["block", null, null, "none"]}>{trumpet?.value}</Text>}
			<AspectRatio ratio={aspectRatio} w={["100%", null, null, "50%"]} flex="1" sx={{
				img: {
					objectFit: isImageCover ? "cover" : "contain",
				}
			}}>
				{image}
			</AspectRatio>
			<Flex flexDir="column" w={["100%", null, null, "50%"]} flex="0 0 auto" justifyContent="space-between">
				<Box>
					{type === "issue" && <Flex
						flexWrap="wrap"
						justifyContent="space-between"
						alignItems="center"
						gap="md"
						mb={["lmd", null, null, "xl"]}
					>
						{issueNumber && <Text size="cardIssueNumber" color={cardTextColor}>{issueNumber}</Text>}
						{(issueMonth || date) && <CardInfo
							id={id}
							params={[issueMonth ?? undefined, date ? new Date(date).getFullYear() : undefined]}
							isDarkBgTheme={isDarkBg}
						/>}
					</Flex>}
					{(trumpet?.value || sitecoreContext.pageEditing) && <Text
						size="cardTrumpet"
						color={cardTextColor}
						display={[type === "issue" ? "block" : "none", null, null, "block"]}
						mb="lmd"
					>
						{overrides?.trumpet ?
							<sc.Text field={trumpet} />
							:
							trumpet?.value
						}
					</Text>}
				</Box>
				<Flex flexDir="column" width={["100%", null, null, "80%"]} flex="1" justifyContent="flex-end">
					{topics && topics.length > 0 && <List display="flex" gap="10px" mb="xs">
						{topics.map((topic) => (
							topic.title !== "" && <ListItem key={`${id}_${topic.id}`}>
								<Tag variant={isDarkBg ? "light" : "dark"} size="sm">{topic.title}</Tag>
							</ListItem>
						))}
					</List>}
					{(title?.value || sitecoreContext.pageEditing) && <Heading as="h2" size="h2" color={cardTextColor}>
						{overrides?.title ?
							<sc.Text field={title} />
							:
							title?.value
						}
					</Heading>}
					{(description?.value || sitecoreContext.pageEditing) && <Text mt={["xs", null, null, "md"]} display={"-webkit-box"}
						sx={{
							WebkitLineClamp: 4,
							WebkitBoxOrient: "vertical",
						}}
						overflow={"hidden"} color={cardTextColor}>
						{overrides?.description ?
							<sc.Text field={description} />
							:
							description?.value
						}
					</Text>}
					{(authors || date || articleLength || articleIssueTitle) && type !== "issue" && <Box mt={["sm", null, null, "md"]}>
						<CardInfo id={id} params={[articleIssueTitle ?? undefined, ...(authors?.map(author => author.name) ?? []), date ? formatDate(date) : undefined, articleLength ? formatTime(articleLength) : undefined]} isDarkBgTheme={isDarkBg} />
					</Box>}
					{buttons && <Box flex="0 0 auto" display="flex" alignItems="flex-end">
						{buttons}
					</Box>}
				</Flex>
			</Flex>
		</Flex>
	)
}

const FeaturedCard = ({ primaryColor = "secondary.darkGreen", url, urlTitle, type = "article", title, cardSpacing = "lg", ...rest }: FeaturedCardProps) => {
	const { sitecoreContext } = useSitecoreContext();

	return (
		<Box py={['lg', null, null, cardSpacing]} bg={primaryColor}>
			<Container>
				{
					url && type !== "issue" && !sitecoreContext.pageEditing ?
						<Link to={url} title={urlTitle} data-group="featured-card" width="100%">
							<FeaturedCardContent type={type} title={title} {...rest} />
						</Link>
						:
						<FeaturedCardContent type={type} title={title} {...rest} />
				}
			</Container>
		</Box>
	)
}

export default FeaturedCard;