/* eslint-disable @typescript-eslint/no-unused-vars */
import { type ComponentStyleConfig } from "@chakra-ui/react";

export const Container = (rtl = true): ComponentStyleConfig => ({
	baseStyle: {
		w: "full",
		maxW: "container.max",
		margin: '0 auto',
		px: ["md", null, null, "lg"],
	},
	sizes: {
		articleContent: {
			maxW: "container.content",
		},
	},
	variants: {},
});
