import { type TextField } from "~/foundation/Jss";
import { Box, Flex, Heading, HStack, } from "@chakra-ui/react";
import { pxToRem } from "../theme/utils/mapPxToRem";
import { sc } from "../Jss";
import BundleIcon from "./ui/bundle-icon";

type ContentSectionBoxHeaderProps = {
	primaryColor?: string,
	title?: TextField,
	iconName?: "download-arrow" | "envelop",
	iconSize?: string,
	isIconRtl?: boolean,
};

const ContentSectionBoxHeader = ({ primaryColor, title, iconName, iconSize, isIconRtl }: ContentSectionBoxHeaderProps) => {
	return (
		<Flex flex="1" gap={pxToRem("12px")}>
			<HStack flex="1">
				<Heading as="h3" size="headline5" width={["70%", null, null, "60%"]} color={primaryColor}>
					<sc.Text field={title} />
				</Heading>
			</HStack>
			{iconName && <Box flex="0 0 auto" sx={{
				svg: {
					fill: primaryColor,
				}
			}}>
				<BundleIcon name={iconName} size={iconSize ?? "lg"} isRtl={isIconRtl ?? false} />
			</Box>}
		</Flex>
	)
}

export default ContentSectionBoxHeader;

