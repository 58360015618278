import { mapPxToRem } from "../../Utils/mapPxToRem";

const typography = {
	lineHeights: {
		small: 0.8,
		normal: "normal",
		none: 1,
		shorter: 1.3,
		short: 1.4,
		base: 1.45,
		tall: 1.5,
		taller: 1.6,
		full: "100%",
	},

	fontWeights: {
		light: 300,
		normal: 400,
		// medium: 500,
		bold: 700,
	},

	fonts: {
		heading: `'Almarai', "sans-serif"`,
		body: `'Almarai', "sans-serif"`,
	},

	fontSizes: mapPxToRem({
		xxs: "13px",
		xs: "14px",
		sm: "15px",
		md: "16px",
		xmd: "18px",
		lg: "20px",
		xl: "24px",
		"2xl": "28px",
		"3xl": "32px",
		"4xl": "40px",
		"5xl": "64px",
		"6xl": "96px",
		"7xl": "120px",
	}),
};

export default typography;
