import { MotionBox } from "~/foundation/Framer/MotionBox";

type ModuleInViewAnimationContainerProps = {
	className?: string;
	children: React.ReactNode;
};

const ModuleInViewAnimationContainer = ({ className, children, ...rest }: ModuleInViewAnimationContainerProps) => {
	return (
		<MotionBox
			className={className}
			initial={{ opacity: 0, transform: "translateY(20px)" }}
			whileInView={{ opacity: 1, transform: "translateY(0px)" }}
			viewport={{ once: true }}
			transition={{ duration: 0.5, ease: "easeInOut" }}
			{...rest}
		>
			{children}
		</MotionBox>
	);
};

export default ModuleInViewAnimationContainer;
