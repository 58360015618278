import { Card, type CardProps } from '../Cards/Card';
import { Box } from '@chakra-ui/react';
//import { HeadlineAndDescription } from '../HeadlineAndDescription/headline-and-description';
import { type CarouselClass, Carousel, CarouselA11y, CarouselSlide } from "./carousel";
import { useEffect, useRef, useState } from 'react';
import "./carousel.css";
import { useColorTheme } from '../../theme/ColorThemeContext';

type OneRowCarouselProps = {
	cards: CardProps[];
};

const OneRowCarousel = ({ cards }: OneRowCarouselProps) => {
	const scrollbarTrackRef = useRef<HTMLDivElement>(null);
	const scrollbarThumbRef = useRef<HTMLDivElement>(null);
	const [swiperInstance, setSwiperInstance] = useState<CarouselClass | null>(null);
	const [, setActiveSlideIndex] = useState(0);
	const [, setPosition] = useState({ x: 0, y: 0 });
	const [, setIsHovered] = useState(false);
	const [isSliderEnabled, setIsSliderEnabled] = useState(true);
	const { headlineColor } = useColorTheme();

	const sliderEnabledStylesForCard = {
		cursor: swiperInstance ? 'grab' : 'auto', // Grab cursor when swiper instance exists
		'&:hover': {
			cursor: 'grab',
			transition: 'box-shadow 0.3s ease-in-out', // Smooth transition for glow
		},
		'&:active': {
			cursor: 'grabbing', // Grabbing cursor when active (while dragging)
			boxShadow: 'none', // Remove glow while dragging
		},
		transition: 'cursor 0.3s ease, box-shadow 0.3s ease', // Smooth transition for cursor and glow
	};

	// Update mouse position
	const handleMouseMove = (e: MouseEvent) => {
		setPosition({ x: e.clientX, y: e.clientY });
	};

	// Add event listener for mouse move
	useEffect(() => {
		document.addEventListener('mousemove', handleMouseMove);
		return () => {
			document.removeEventListener('mousemove', handleMouseMove);
		};
	}, []);

	const isSlideFullyVisible = (slide: HTMLElement) => {
		if (!slide) return false;
		const rect = slide.getBoundingClientRect();
		return rect.left >= 0 && rect.right <= window.innerWidth;
	};

	useEffect(() => {
		if (!swiperInstance) return;

		const updateSliderState = () => {
			const slides = swiperInstance.slides || [];
			const hasSlideOutsideViewport = slides.some((slide) => !isSlideFullyVisible(slide));

			// Enable or disable slider based on whether any slide is partially or fully outside the viewport
			swiperInstance.allowTouchMove = hasSlideOutsideViewport;
			swiperInstance.allowSlideNext = hasSlideOutsideViewport;
			swiperInstance.allowSlidePrev = hasSlideOutsideViewport;

			// Scroll to the first slide if all slides are fully visible
			if (!hasSlideOutsideViewport) {
				// swiperInstance.slideTo(0);
			}

			// Update state to control scrollbar visibility
			setIsSliderEnabled(hasSlideOutsideViewport);
		};

		// Listen for resize events to recalculate on window resize
		window.addEventListener("resize", updateSliderState);

		// Run the check on component mount
		updateSliderState();

		// Cleanup event listener on unmount
		return () => {
			window.removeEventListener("resize", updateSliderState);
		};
	}, [swiperInstance]);



	useEffect(() => {
		if (!swiperInstance || !scrollbarThumbRef.current || !scrollbarTrackRef.current) return;

		const updateScrollbar = () => {
			if (!swiperInstance || !swiperInstance.params) return;

			const { slidesPerView } = swiperInstance.params;

			// Determine the number of visible slides
			let visibleSlides: number;

			if (typeof slidesPerView === "number") {
				visibleSlides = slidesPerView; // Directly use the number if defined
			} else if (slidesPerView === "auto") {
				const containerWidth = swiperInstance.el.offsetWidth || 0;
				const slideWidth =
					swiperInstance.slides[0]?.offsetWidth || containerWidth; // Fallback if slide width is unavailable
				visibleSlides = Math.max(1, Math.floor(containerWidth / slideWidth)); // Avoid 0 slides
			} else {
				visibleSlides = swiperInstance.slides.length; // Fallback to all slides if slidesPerView is undefined
			}

			const totalSlides = swiperInstance.slides.length || 1; // Avoid division by zero
			const progress = swiperInstance.progress || 0;

			// Calculate thumb width as a percentage of the progress
			const thumbWidth = (progress + visibleSlides / totalSlides) * 100;

			// Update scrollbar thumb styles
			if (scrollbarThumbRef.current) {
				scrollbarThumbRef.current.style.width = `${Math.min(thumbWidth, 100)}%`; // Ensure it doesn't exceed 100%
				scrollbarThumbRef.current.style.left = `0`; // Always start at 0
			}
		};

		// Attach Swiper event listeners
		swiperInstance.on("progress", updateScrollbar);
		swiperInstance.on("slideChange", updateScrollbar);

		// Initial update
		updateScrollbar();

		// Cleanup event listeners
		return () => {
			swiperInstance.off("progress", updateScrollbar);
			swiperInstance.off("slideChange", updateScrollbar);
		};
	}, [swiperInstance]);

	return (
		<>
			<Carousel
				resistance={true}
				observeParents={true}
				spaceBetween={20}
				modules={[CarouselA11y]}
				slidesPerView={"auto"}
				overflow="visible"
				pos={'relative'}
				onSwiper={(swiper) => {
					setSwiperInstance(swiper);
				}}
				onSlideChange={({ activeIndex }) => {
					setActiveSlideIndex(activeIndex);
				}}
				sx={{
					width: "100%",
				}}
			>
				{cards.map((card, index) => (
					<CarouselSlide
						key={index}
						width={{
							base: index === 0 || card.cardType === "event" ? "85% !important" : "48% !important",
							...card.cardType === "event" && { md: index === 0 ? "85% !important" : "48% !important" },
							lg: index === 0 ? "50% !important" : "25% !important",
						}}
						pos={'relative'}
						onMouseEnter={() => {
							setIsHovered(true)
						}}
						onMouseLeave={() => {
							setIsHovered(false)
						}}
					>
						<Card
							title={card.title}
							date={card.date}
							time={card.time}
							location={card.location}
							text={card.text}
							imageUrl={card.imageUrl}
							url={card.url}
							size={card.cardType === "event" ? "tall" : (index === 0 ? "medium" : "small")}
							highlightedOnMobile={index === 0}
							sx={isSliderEnabled ? { ...sliderEnabledStylesForCard } : {}}
							numberOfLines={card.cardType === "event" ? 3 : 5}
							{...card.cardType === "event" && { cardType: "event", height: [423, null, null, index === 0 || cards.length === 2 ? 353 : 423] }}
						/>
					</CarouselSlide>
				))}
				{/* {isHovered && (
					<Box
						position="fixed"
						top={`${position.y}px`}
						left={`${position.x}px`}
						width="30px"
						height="30px"
						borderRadius="50%"
						background="rgba(0, 255, 255, 0.4)"
						boxShadow="0 0 15px rgba(0, 255, 255, 0.7)"
						transition="all 0.2s ease"
						pointerEvents="none"
						zIndex="999"
					/>
				)} */}

			</Carousel>
			<Box
				ref={scrollbarTrackRef}
				position="relative"
				height="4px"
				width="100%"
				bg="gray.200"
				borderRadius="4px"
				overflow="hidden"
				mt={"3rem"}
				visibility={isSliderEnabled ? 'visible' : 'hidden'} // Show scrollbar when enabled
			>
				<Box
					ref={scrollbarThumbRef}
					position="absolute"
					left="0"
					h="100%"
					w={`${Math.max(10, 100 / cards.length)}%`}
					bg={headlineColor}
					borderRadius="4px"
					transition="all 0.3s ease"
				/>
			</Box>
		</>
	)
};

export { OneRowCarousel };
