import React, { type FC } from "react";
import { Image, useSitecoreContext } from "@sitecore-jss/sitecore-jss-react"; // eslint-disable-line no-restricted-imports
import { type ImageProps, type ImageFieldValue } from "../";
import LazyLoad from 'react-lazy-load';
import parse from 'html-react-parser';

// To avoid deprecation warning...
// eslint-disable-next-line @typescript-eslint/no-explicit-any
(Image as any).defaultProps = undefined;

const ImageWrapper: FC<ImageProps> = (props) => {
	const { sitecoreContext } = useSitecoreContext();

	const newProps = { alt: "", ...props };
	delete newProps.lazy;

	if (sitecoreContext.pageEditing) {
		return <Image {...newProps} />;
	}

	if (props.field?.value) {
		const { height, width } = props.field.value as ImageFieldValue;

		if (props.field.value.svgContent) {
			if (!props.lazy) {
				//return <span dangerouslySetInnerHTML={{ __html: props.field?.value?.svgContent }}></span>
				return <>{parse(props.field.value.svgContent)}</>;
			}

			if (!props.field.value) {
				return <Image {...newProps} />;
			}

			return (
				<LazyLoad height={height} width={width}>
					<>{parse(props.field.value.svgContent)}</>
				</LazyLoad>
			)
		}

		if (props.lazy) {
			const { height, width } = props.field.value as ImageFieldValue;

			return (
				<LazyLoad height={height} width={width}>
					<Image {...newProps} />
				</LazyLoad>
			);
		}
	}


	return <Image {...newProps} />;
};

export default ImageWrapper;