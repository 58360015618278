import { type ComponentStyleConfig } from "@chakra-ui/react";

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const Text = (): ComponentStyleConfig => ({
	baseStyle: {
		fontFamily: "body",
		fontSize: "md",
		fontWeight: "normal",
		lineHeight: "tall",
	},
	sizes: {
		bodyS: {
			fontSize: "xs",
			lineHeight: "tall",
		},
		bodyL: {
			fontSize: ["md", null, null, "xmd"],
		},
		cardTrumpet: {
			fontSize: ["lg", null, null, "xl"],
		},
		cardIssueNumber: {
			fontSize: ["xl", null, null, "3xl"],
		},
		caption: {
			fontSize: "xs",
			lineHeight: "tall",
		},
		quote: {
			fontSize: ["3xl", null, null, "5xl"],
			lineHeight: "shorter",
		},
		quoteSign: {
			fontSize: ["5xl", null, null, "7xl"],
			lineHeight: [1.2, null, null, 1.1],
		},
		shareLabel: {
			fontSize: ["md", null, null, "lg"],
			lineHeight: "taller",
		}
	},
	variants: {},
});

