import React from "react";
import { Skeleton } from "@chakra-ui/react";
import { sc } from "../../Jss";

type ImageSkeletonContainerProps = {
	isSizeContain?: boolean;
	children?: React.ReactNode;
	advancedImageProps?: object;
}

const ImageSkeletonContainer = ({ children, advancedImageProps, isSizeContain = false }: ImageSkeletonContainerProps) => {
	return (
		<>
			{!isSizeContain && <Skeleton
				sx={{
					"--skeleton-start-color": "rgba(0, 0, 0, 0.05)",
					"--skeleton-end-color": "rgba(0, 0, 0, 0.15)",
				}}
				w="100%"
				h="100%"
				variant="pulse"
			/>}
			{children ??
				<sc.AdvanceImage
					{...advancedImageProps}
				/>
			}
		</>
	)
}

export default ImageSkeletonContainer;

