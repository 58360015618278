import { useEffect, useState } from "react";
import { type ItemReference } from "~/foundation/Jss";
import { type SearchFilterModel } from "~/feature/Search/generated-types";
import { searchContentService } from "~/feature/Search/services/searchContentService";
import { type ISiteSearchResultItemModel } from "../ArticleList";

interface SearchComponentProps {
	searchRoot: string;
	onResultsChange?: (results: ISiteSearchResultItemModel[] | null) => void;
	onTotalResultsChange: (count: number) => void;
	onLoadingChange?: (loading: boolean) => void;
	pageSize?: number;
}

export const useSearch = ({
	searchRoot,
	onResultsChange,
	onTotalResultsChange,
	onLoadingChange,
	pageSize = 12
}: SearchComponentProps) => {
	const [searchQuery, setSearchQuery] = useState("");
	const [categories, setCategories] = useState<ItemReference[]>([]);
	const [page, setPage] = useState<number>(1);
	const [initialLoad, setInitialLoad] = useState(true);
	const defaultPageSize = 12;
	const [loading, setLoading] = useState(false);

	const handleSearch = () => {
		onLoadingChange && onLoadingChange(true);
		setLoading(true);
		setInitialLoad(false);

		const apiQueryStrings: SearchFilterModel = {
			query: searchQuery,
			page,
			pageSize: pageSize ?? defaultPageSize,
			sort: 'date',
			categories: categories.map(c => c.id).join('|'),
		};

		searchContentService.allSearch(searchRoot, apiQueryStrings)
			.then(response => {
				if (response && response.statusCode === 200 && response.data) {
					if (Array.isArray(response.data.results)) {
						onResultsChange && onResultsChange(response.data.results.map(result => ({
							...result,
							topicTags: [] // Add missing required topicTags property
						})));
						onTotalResultsChange(response.data.totalResults);
						onLoadingChange && onLoadingChange(false);
						setLoading(false);
					}
				}
			})
			.catch(() => {
				console.error("Error fetching search results");
				onLoadingChange && onLoadingChange(false);
				setLoading(false);
			});
	};

	const handleCategorySelect = (category: ItemReference) => {
		setPage(1);
		setCategories(prev =>
			prev.includes(category)
				? prev.filter(c => c !== category)
				: [...prev, category]
		);
	};

	const resetSearch = () => {
		setSearchQuery("");
		setCategories([]);
		setPage(1);
		onTotalResultsChange(0);
		onResultsChange && onResultsChange(null);
		onLoadingChange && onLoadingChange(false);
		setInitialLoad(true);
	};

	useEffect(() => {
		if (!initialLoad) {
			handleSearch();
		}
	}, [categories, page]);

	return {
		searchQuery,
		setSearchQuery,
		categories,
		page,
		setPage,
		handleSearch,
		handleCategorySelect,
		resetSearch,
		initialLoad,
		loading,
		pageSize,
		setInitialLoad
	};
};