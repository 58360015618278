import { AspectRatio, Flex, Text, type ResponsiveValue } from "@chakra-ui/react";
import { type TextField, useSitecoreContext } from "~/foundation/Jss";
import { sc } from "../../Jss";

type ImageProps = {
	aspectRatio?: ResponsiveValue<number>;
	aspectRatioContainer?: boolean;
	isDesktopAspectRatio?: boolean;
	caption?: TextField;
	children: React.ReactNode;
}

const Image: React.FC<ImageProps> = ({ aspectRatio, aspectRatioContainer = true, isDesktopAspectRatio = true, caption, children }) => {
	const { sitecoreContext } = useSitecoreContext();

	return (
		<Flex flexDir="column" w="100%">
			{aspectRatioContainer ?
				<AspectRatio ratio={aspectRatio ?? 1} flex={["0 0 auto", null, null, isDesktopAspectRatio ? "0 0 auto" : 1]}>
					{children}
				</AspectRatio>
				:
				children
			}
			{(caption?.value || sitecoreContext.pageEditing) &&
				<Text size="caption" mt="md" flex="0 0 auto">
					<sc.Text field={caption} />
				</Text>}
		</Flex>
	);
}

export default Image;