/* eslint-disable @typescript-eslint/no-unused-vars */
import { extendBaseTheme } from "@chakra-ui/react";
import { type DeepPartial } from "utility-types";
import { getGlobalStyling } from "./styles/global";
import breakpoints from "./variables/breakpoints";
import sizes from "./variables/sizes";
import zIndices from "./variables/zIndices";
import spacing from "./variables/spacing";
import transition from "./variables/transition";
import typography from "./variables/typography";
import { theme as chakraTheme } from "@chakra-ui/theme";
import { Container } from "./components/Container";
import { Heading } from "./components/Heading";
import { Button } from "./components/Buttons";
import { Text } from "./components/Text";
import { Link } from "./components/Link";
import { Icon } from "./components/Icon";
import { colors } from "./variables/colors";
import { Chip } from "./components/Chip";
import { LinkButton } from "./components/LinkButton";
import { Tag } from "./components/Tag";
import { Select } from "./components/Select";
import { Input } from "./components/Input";
import deepmerge from "deepmerge";

const {
	Button: ChakraButton,
	Heading: ChakraHeading,
	Select: ChakraSelect,
	Input: ChakraInput,
} = chakraTheme.components;

const foundations = (rtl = true) => ({
	getGlobalStyling: getGlobalStyling(),
	direction: rtl ? "rtl" : "ltr",
	breakpoints,
	colors,
	sizes,
	space: spacing,
	transition,
	...typography,
	zIndices,
});

const components = (rtl: boolean = true) => ({
	Container: {
		...Container(),
	},
	Text: {
		...Text(),
	},
	Heading: {
		...ChakraHeading,
		...Heading(),
	},
	Button: {
		...ChakraButton,
		...Button(rtl),
	},
	Link: {
		...Link(rtl),
	},
	Icon: {
		...Icon(),
	},
	Chip: {
		...Chip(rtl),
	},
	LinkButton: {
		...LinkButton(rtl),
	},
	Tag: {
		...Tag(),
	},
	Select: {
		...deepmerge(ChakraSelect, Select)
	},
	Input: {
		...deepmerge(ChakraInput, Input)
	}
});

const defaultTheme = (rtl: boolean) => ({
	name: "Default",
	...foundations(rtl),
	components: components(rtl),
	styles: getGlobalStyling(),
});

export type Theme = DeepPartial<ReturnType<typeof defaultTheme>> & {
	name: string;
};

export function getDefaultTheme(rtl = false) {
	const theme = defaultTheme(rtl);
	return extendBaseTheme(theme) as Theme;
}

// used to generate chakra-types
export default getDefaultTheme(false);
