import { Flex, Text } from "@chakra-ui/react";
import { type TextField } from "~/foundation/Jss";
import { Link } from "../Link";
import { useSitecoreContext } from "~/foundation/Jss/useSitecoreContext";
import { pxToRem } from "../../Utils/mapPxToRem";
export interface AuthorInCardInfoProps {
	id?: string;
	url?: string;
	fields?: {
		fullName?: TextField;
	}
}

type AuthorDisplayProps = {
	id?: string;
	name?: string;
	url?: string;
}

type CardInfoProps = {
	id?: string;
	params: Array<string | number | AuthorDisplayProps | undefined>;
	isDarkBgTheme: boolean;
}

const CardInfo = ({ id, params, isDarkBgTheme }: CardInfoProps) => {
	const { sitecoreContext } = useSitecoreContext();

	return (
		<Flex flexWrap="wrap" rowGap="xxs">
			{params.map((param, index) => (
				param && <Flex
					alignItems="center"
					key={`card_info_${id}_${index}`}
					sx={{
						"&:not(:last-of-type)": {
							_after: {
								content: `""`,
								display: "inline-block",
								width: "1px",
								height: pxToRem("16px"),
								backgroundColor: isDarkBgTheme ? "tints.white.30" : "tints.dark.20",
								mx: "xs"
							}
						}
					}}
				>
					{(typeof param === 'string' || typeof param === 'number') ?
						<Text
							color={isDarkBgTheme ? "tints.white.70" : "tints.dark.80"}
							size="bodyS"
							display="inline-flex"
							alignItems="center"
						>
							{param}
						</Text>
						:
						<Link
							{...sitecoreContext.pageEditing && { as: "p", pointerEvents: "none" }}
							to={param?.url}
							title={param?.name}
							color={isDarkBgTheme ? "tints.white.70" : "tints.dark.80"}
							size="linkS"
							display="inline-flex"
							alignItems="center"
							py="xxs"
							_hover={{
								textDecoration: "underline"
							}}
						>
							{param?.name ?? ""}
						</Link>
					}
				</Flex>
			))}

		</Flex>
	);
}

export default CardInfo;