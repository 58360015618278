/* eslint-disable @typescript-eslint/no-unused-vars */
import { type ComponentStyleConfig } from "@chakra-ui/react";
import { colors } from "../variables/colors";

export const getTagVariants = () => ({
	'dark': {
		backgroundColor: colors.tints.dark[10],
		color: colors.primary.dark,
		container: {
			backgroundColor: colors.tints.dark[10],
			color: colors.primary.dark,
		},
	},
	'light': {
		container: {
			backgroundColor: colors.tints.white[10],
			color: colors.primary.white,
		}
	},
	'colored': {
		container: {
			backgroundColor: "inherit",
			color: "inherit",
		}
	},
});

export type TagVariant = keyof (ReturnType<typeof getTagVariants>);

export const Tag = (): ComponentStyleConfig => ({
	baseStyle: {
		container: {
			fontSize: 'xs',
			px: 'xs',
			py: "xxs",
		}
	},
	variants: getTagVariants(),
	defaultProps: {
		variant: "dark",
	},
});