import { type FC } from 'react';
import { Flex, Button } from "@chakra-ui/react";
import { ButtonIcon } from '../../_Base/Components/UI/Button';
import { pxToRem } from '../../_Base/Utils/mapPxToRem';
import { useTranslation } from '~/foundation/Dictionary';
import { type GlobalDictionary } from '../../dictionary';

interface PaginationProps {
	currentPage: number;
	totalPages: number;
	maxVisiblePages?: number;
	onPageChange: (page: number) => void;
	variant?: 'light' | 'dark';
}

const Pagination: FC<PaginationProps> = ({
	currentPage,
	totalPages,
	maxVisiblePages = 5,
	onPageChange,
	variant = 'dark'
}) => {
	const [t] = useTranslation<GlobalDictionary>();
	if (totalPages <= 1) return null;

	const buttonVariant = variant === 'dark' ? 'unstyled-w-hover-dark' : 'unstyled-w-hover-light';
	const pageButtonVariant = variant === 'dark' ? 'bordered-dark' : 'bordered-light';
	const activePageButtonVariant = variant === 'dark' ? 'dark' : 'light';

	return (
		<Flex
			justifyContent={{
				base: "center",
				md: "start",
			}}
			gap="12px"
		>
			<ButtonIcon
				icon="chevron-left"
				size="lg"
				iconSize={pxToRem("32px")}
				variant={buttonVariant}
				transform="rotate(180deg)"
				onClick={() => onPageChange(currentPage - 1)}
				isDisabled={currentPage === 1}
				aria-label={t('button.pagination.previousPage')}
				padding={0}
			/>

			{[...Array(Math.min(maxVisiblePages, totalPages))].map((_, idx) => {
				let pageNum;
				if (totalPages <= maxVisiblePages) {
					pageNum = idx + 1;
				} else {
					const middle = Math.floor(maxVisiblePages / 2);
					if (currentPage <= middle) {
						pageNum = idx + 1;
					} else if (currentPage > totalPages - middle) {
						pageNum = totalPages - maxVisiblePages + idx + 1;
					} else {
						pageNum = currentPage - middle + idx;
					}
				}

				return (
					<Button
						key={pageNum}
						variant={currentPage === pageNum ? activePageButtonVariant : pageButtonVariant}
						size="page"
						onClick={() => onPageChange(pageNum)}
						aria-label={`${t("button.pagination.page")} ${pageNum}`}
						pointerEvents={currentPage === pageNum ? "none" : "auto"}
					>
						{pageNum}
					</Button>
				);
			})}

			<ButtonIcon
				icon="chevron-left"
				size="lg"
				iconSize={pxToRem("32px")}
				variant={buttonVariant}
				onClick={() => onPageChange(currentPage + 1)}
				isDisabled={currentPage === totalPages}
				aria-label={t('button.pagination.nextPage')}
				padding={0}
			/>
		</Flex>
	);
};

export default Pagination;