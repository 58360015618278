import { Tag as ChakraTag, TagLabel, type TagProps as ChakraTagProps } from "@chakra-ui/react"
import { useColorTheme } from "../../Theme/ColorThemeContext";
import { type TextField } from "~/foundation/Jss";
// import { useColorTheme } from "../../Theme/ColorThemeContext";

export interface TopicTag {
	id: string;
	name: string;
	fields: {
		title: TextField;
		theme: {
			value: string;
		};
	};
}

export type TagProps = ChakraTagProps

const Tag = ({
	variant,
	children,
	...props
}: TagProps) => {
	const { backgroundColor, color } = useColorTheme().colors.tags;
	// Determine props conditionally based on the variant
	const conditionalProps = variant === 'colored'
		? { bg: backgroundColor, color: color }
		: {};

	return (
		<ChakraTag
			variant={variant}
			{...conditionalProps}
			{...props}
		>
			<TagLabel>{children}</TagLabel>
		</ChakraTag>
	)
}

export { Tag }