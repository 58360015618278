import React, { type FC, createContext, useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { type SearchFilterModel } from "~/feature/Search/generated-types";

type SearchContextData = {
	facets: SearchFilterModel;
	totalResultsCount: number;
	setTotalResultsCount: React.Dispatch<React.SetStateAction<number>>;
	loadingResults: boolean;
	setLoadingResults: React.Dispatch<React.SetStateAction<boolean>>;
}

export const SearchContext = createContext<SearchContextData>({ facets: {} } as any); // eslint-disable-line @typescript-eslint/no-explicit-any

type SearchProviderProps = {
	children: React.ReactNode;
}

export const SearchProvider: FC<SearchProviderProps> = ({ children }) => {
	const [facets, setFacets] = useState<SearchFilterModel>({});
	const [totalResultsCount, setTotalResultsCount] = useState<number>(0);
	const [loadingResults, setLoadingResults] = useState<boolean>(false);
	const [searchParams] = useSearchParams();

	useEffect(() => {
		const urlParams = new URLSearchParams(searchParams);
		const params = Object.fromEntries(urlParams);
		setFacets(params);
	}, [searchParams]);

	return (
		<SearchContext.Provider value={{ facets, setTotalResultsCount, totalResultsCount, setLoadingResults, loadingResults }}>
			{children}
		</SearchContext.Provider>
	);
};