import { Box, Text, type TextProps } from "@chakra-ui/react";
import { motion, useInView } from "framer-motion";
import { type RefObject, useMemo, useRef } from "react";
import { type TextProps as SCTextProps } from "~/foundation/Jss/components/TextWrapper";
import { sc } from "../../_Base/Jss";
import { animationOptions } from "../Theme/variables/animation";

const MotionText = motion(Text)

type PresenceTextProps = TextProps & SCTextProps & {
	once?: boolean
	isRtl?: boolean
	isDate?: boolean
	children?: React.ReactNode
}

export const PresenceText = ({ field, children, once = true, isRtl = false, isDate = false, ...props }: PresenceTextProps) => {
	const inViewRef = useRef<HTMLDivElement | null>(null)
	const isInView = useInView(inViewRef as RefObject<HTMLDivElement>, { once })

	const formattedField = useMemo(() => {
		if (isDate) {
			const date = field?.value ? new Date(field.value) : new Date();
			const options = { year: 'numeric' as const, month: 'short' as const, day: 'numeric' as const };
			const locale = isRtl ? "ar-EG" : "en-US";
			const formattedDate = new Intl.DateTimeFormat(locale, options).format(date);
			return { ...field, value: formattedDate }
		}
		return field
	}, [field, isRtl, isDate])

	if (!field && !children) return null;

	return (
		<Box
			ref={inViewRef}
			overflow={"hidden"}
		>
			<MotionText
				initial={{ opacity: 0, y: "15%" }}
				animate={isInView ? { opacity: 1, y: 0 } : {}}
				transition={animationOptions({})}
				style={{ willChange: "transform" }}
				{...props}>
				{formattedField ? <sc.Text field={formattedField} /> : children}
			</MotionText>
		</Box>
	)
}