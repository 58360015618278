import type { ReactNode } from "react";
import { type LinkProps, Flex, Text } from "@chakra-ui/react";
import { useColorTheme } from "../../theme/ColorThemeContext";
import BundleIcon from "./bundle-icon";
import { type LinkField, useSitecoreContext } from "~/foundation/Jss";
import { sc } from "../../Jss";
import { groupHoverArrowStyle } from "../../theme/animations";

type ModuleLinkProps = LinkProps & {
	children?: ReactNode;
	hasIcon?: boolean;
	link?: LinkField;
	isComponent?: boolean;
};

export const ModuleLink = ({
	children,
	hasIcon,
	size = "lg",
	link,
	isComponent = false,
}: ModuleLinkProps) => {
	const { headlineColor } = useColorTheme();
	const { sitecoreContext } = useSitecoreContext();
	const isRtl = sitecoreContext.custom.settings.isRtl;

	return (
		<Flex h="fit-content" w="fit-content" display="inline-flex" alignItems="center" gap={5} data-group>
			{isComponent === false || sitecoreContext.pageEditing ?
				<>
					<sc.Link field={link} color={headlineColor} size="moduleLink" />
					{hasIcon && (
						<BundleIcon
							name="button-arrow"
							fill={headlineColor}
							size={`${size}`}
							isRtl={isRtl}
							transition="all 0.3s ease"
							_groupHover={groupHoverArrowStyle(isRtl)}
						/>

					)}
				</>
				:
				<>
					<Text size="ModuleLinkText" color={headlineColor} textTransform="capitalize">{children}</Text>
					{hasIcon && (
						<BundleIcon
							name="button-arrow"
							fill={headlineColor}
							size={`${size}`}
							isRtl={isRtl}
							transition="all 0.3s ease"
							_groupHover={groupHoverArrowStyle(isRtl)}
						/>
					)}
				</>
			}
		</Flex>
	);
};
