import React, { type FC, type PropsWithChildren } from "react";
import { type ImageField, type ImageSizeParameters } from "@sitecore-jss/sitecore-jss-react";
import { FieldEditFrame } from "./FieldEditFrame";
import { useSitecoreContext } from "../useSitecoreContext";
// eslint-disable-next-line no-restricted-imports
import { Image as ChakraImage, type ImageProps as ChakraImageProps } from "@chakra-ui/react";
import { mediaApi } from "..";
import { LazyImage } from "~/foundation/Components/Image/LazyImage";

type ChakraImagePropsWithoutSrcSet = Omit<ChakraImageProps, "srcSet">;

type RenderImageProps = ChakraImagePropsWithoutSrcSet & SharedProps & {
	field: ImageField;
	pageEditing: boolean;
}

type SharedProps = {
	srcSet?: ImageSizeParameters[];
	imageParams?: { [paramName: string]: string | number };
	mediaUrlPrefix?: RegExp;
	lazy?: boolean;
}

const RenderImage: FC<RenderImageProps> = ({ field, srcSet, imageParams, mediaUrlPrefix, pageEditing, lazy = true, ...rest }) => {
	const { src, alt } = field.value || {};

	if (!src && pageEditing) {
		return <></>;
	}

	if (!src) {
		return <></>;
	}

	const newAttrs: { [attr: string]: unknown } = {
		alt: alt ?? "",
		...rest
	};

	// update image URL for jss handler and image rendering params
	const resolvedSrc = mediaApi.updateImageUrl(src, imageParams, mediaUrlPrefix);
	if (srcSet) {
		// replace with HTML-formatted srcset, including updated image URLs
		newAttrs.srcSet = mediaApi.getSrcSet(resolvedSrc, srcSet, imageParams, mediaUrlPrefix);
	}
	// always output original src as fallback for older browsers
	newAttrs.src = resolvedSrc;

	if (pageEditing || !lazy) {
		return <ChakraImage className="fadeIn" {...newAttrs} />;
	}

	return (
		<LazyImage className="fadeIn" {...newAttrs} />
	);
}

type AdvanceImageProps<T> = ChakraImagePropsWithoutSrcSet & SharedProps & {
	/**
	 * itemId: Id of the item the advance image in on. Usually rendering.dataSource or sitecoreContext.itemId
	 */
	itemId?: string;

	/**
	 * fields object. Usually props.fields or sitecoreContext.route.fields
	 */
	fields?: T;
	fieldName?: keyof T;
	editable?: boolean;
	fieldNames?: keyof T[] | string[];
	field?: ImageField;
	lazyHeight?: string | number;
	lazyWidth?: string | number;
}

export function AdvanceImage<T,>({ itemId, fields, fieldName, field, fieldNames, editable = true, lazy = true, ...rest }: PropsWithChildren<AdvanceImageProps<T>>) {
	const { sitecoreContext } = useSitecoreContext();

	if (!itemId && editable) {
		console.warn("AdvanceImage - itemId is required");
		return <></>;
	}

	if (field && fields) {
		console.warn("AdvanceImage: both fields and field cannot be set")
	}

	let fieldObj = field;
	if (!field && fields && fieldName) {
		fieldObj = fields[fieldName] as ImageField;
	}

	if (!fieldObj) {
		console.warn(`AdvanceImage: Field not found ${fieldName as string} on item ${itemId}`);
		return <></>;
	}

	if (!fieldObj.value?.src && !sitecoreContext.pageEditing) {
		return <></>;
	}

	if (!editable) {
		return <RenderImage field={fieldObj} {...rest} pageEditing={sitecoreContext.pageEditing || false} lazy={lazy} />;
	}

	return (
		<FieldEditFrame itemId={itemId} fields={(fieldNames || [fieldName]) as string[]}>
			<RenderImage field={fieldObj} {...rest} pageEditing={sitecoreContext.pageEditing || false} lazy={lazy} />
		</FieldEditFrame>
	)
}