import { type VariantThemeId, type ColorTheme } from "./color-themes/dark-blue-color-theme";
import { type ColorThemeId, themes } from "./themes";
import { type ColorThemeContextValue } from "./ColorThemeContext.tsx";

export const getColorTheme = (colorThemeId: ColorThemeId): ColorTheme => {
	return themes[colorThemeId]?.() || themes.dark_blue();
}

export const getColorThemeVariant = (colorThemeId: ColorThemeId, variant: VariantThemeId): ColorThemeContextValue => {
	const theme = getColorTheme(colorThemeId);
	const colors = theme.variants[variant] || theme.variants.white;

	return { name: `${colorThemeId}--${variant}`, ...colors }
}
