import { useEffect, useRef, useState } from "react";
import { type VideoComponentProps } from "./VideoTypes";
import { useSitecoreContext } from "~/foundation/Jss";
import { AspectRatio, Box, Text, Flex } from "@chakra-ui/react";
import { videoThumbnailMotion } from "../../../_Base/theme/styles/motions";
import { MotionBox } from "~/foundation/Framer/MotionBox";
import { useEventEmitter } from "~/foundation/Events";
import { type OneTrustEvent } from "~/foundation/OneTrust/OneTrustEvent";
import { getOneTrustTargetingConsent } from "~/foundation/Utils/cookie";
import { useInView } from "framer-motion";
import { pxToRem } from "../../../_Base/theme/utils/mapPxToRem";
import { CookieConsent } from "../../../_Base/components/OneTrust/CookieConsent";
import BundleIcon from "../../../_Base/components/ui/bundle-icon";
import ImageComponent from "../Image/ImageComponent";
import VideoOverlay from "./VideoOverlay";

const VideoPlayIcon = () => (
	<Box display="flex" position="absolute" alignItems="center" justifyContent="center" top="50%" left="50%" transform="translateX(-50%) translateY(-50%)">
		<BundleIcon name="play" fill="white" size={["lg", null, null, "xl"]} />
	</Box>
);

const Video = ({ videoId, videoTitle, caption, isAspectRatio, isOverlay = false, isImgSrc = false, children }: VideoComponentProps) => {
	const [showVideoOverlay, setShowVideoOverlay] = useState<boolean>(false);
	const [loaded, setLoaded] = useState<boolean>(false);
	const { sitecoreContext } = useSitecoreContext();
	const moduleRef = useRef<HTMLElement>(null);
	const isInView = useInView(moduleRef as React.RefObject<HTMLElement>);
	const [hasTargetingConsent, setHasTargetingConsent] = useState(false);
	const eventEmitter = useEventEmitter<OneTrustEvent>("OneTrust");
	const isRtl = sitecoreContext.custom.settings.isRtl;

	const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
		if (event.key === "Enter") {
			setLoaded(true);
		}
	};

	useEffect(() => {
		setHasTargetingConsent(getOneTrustTargetingConsent());
		eventEmitter.on("onConsentChanged", () => {
			setHasTargetingConsent(getOneTrustTargetingConsent());
		})
		if (isOverlay) {
			setShowVideoOverlay(true);
		}
	}, [])

	useEffect(() => {
		if (loaded) {
			setShowVideoOverlay(true);
		}
	}, [loaded]);

	return (
		loaded ||
			//(isInView && !sitecoreContext.custom.settings.cookieConsent.useOneTrust) ||
			(isInView && hasTargetingConsent && sitecoreContext.custom.settings.cookieConsent.useOneTrust) ?
			<>
				{isOverlay ?
					<>
						<Box as="button" onClick={() => setShowVideoOverlay(true)} cursor="pointer" width="100%" height="100%">
							<MotionBox {...!isAspectRatio ? { height: "100%" } : {}} initial="blur" whileHover="hover" animate="blur" onClick={() => setLoaded(true)} onKeyDown={handleKeyDown} tabIndex={0} _hover={{ cursor: "pointer" }}>
								<MotionBox initial="blur" whileHover="hover" animate="blur" variants={videoThumbnailMotion} width="100%" height="100%" position="relative" _after={{ content: `""`, position: 'absolute', top: "0", right: '0', bottom: '0', left: '0', background: isImgSrc ? "rgba(0, 0, 0, 0.40)" : "none", pointerEvents: "none" }}>
									<ImageComponent isImgSrc={isImgSrc}>
										{children}
									</ImageComponent>
								</MotionBox>
								{!sitecoreContext.pageEditing && <VideoPlayIcon />}
							</MotionBox>
						</Box>
						{showVideoOverlay && <VideoOverlay showOverlayHandle={setShowVideoOverlay}>
							<Flex flexDir="column">
								<iframe src={`https://player.vimeo.com/video/${videoId?.value}?h=b74c66a7ef&badge=0&autopause=0&autoplay=1&player_id=0&app_id=58479&dnt=1`} height="100%" width="100%" allow="autoplay; fullscreen; picture-in-picture" allowFullScreen title={videoTitle?.value} />
								<Text as="caption" size="imageCaption" mt={pxToRem("12px")} display="flex" width="100%" color="white" textAlign={isRtl ? 'right' : 'left'} {...isRtl ? { pr: "30px" } : { pl: "30px" }}>
									{caption?.value}
								</Text>
							</Flex>
						</VideoOverlay>
						}
					</>
					:
					<iframe src={`https://player.vimeo.com/video/${videoId?.value}?h=b74c66a7ef&badge=0&autopause=0&autoplay=1&player_id=0&app_id=58479&dnt=1`} height="100%" width="100%" allow="autoplay; fullscreen; picture-in-picture" allowFullScreen title={videoTitle?.value} />
				}
			</>
			:
			<MotionBox {...!isAspectRatio ? { height: "100%", width: "100%" } : {}} initial="blur" whileHover="hover" animate="blur" {...hasTargetingConsent && sitecoreContext.custom.settings.cookieConsent.useOneTrust && { onClick: () => setLoaded(true), onKeyDown: handleKeyDown }} tabIndex={0} _hover={{ cursor: "pointer" }}>
				<MotionBox initial="blur" whileHover="hover" animate="blur" variants={videoThumbnailMotion} width="100%" height="100%" position="relative" _after={{ content: `""`, position: 'absolute', top: "0", right: '0', bottom: '0', left: '0', background: isImgSrc ? "rgba(0, 0, 0, 0.40)" : "none", pointerEvents: "none" }}>
					<ImageComponent isImgSrc={isImgSrc}>
						{children}
					</ImageComponent>
				</MotionBox>
				{!hasTargetingConsent && sitecoreContext.custom.settings.cookieConsent.useOneTrust ?
					<CookieConsent position="absolute" top="0" left="0" zIndex="2" />
					:
					!sitecoreContext.pageEditing && <VideoPlayIcon />
				}
			</MotionBox>
	)
}

const VideoComponent = ({ videoId, isImgSrc = false, isAspectRatio, isOverlay = false, isShadow = false, children, aspectRatio, ...rest }: VideoComponentProps) => {
	const moduleRef = useRef<HTMLDivElement | null>(null);

	return (

		<>
			{isAspectRatio ?
				<AspectRatio ref={moduleRef} ratio={aspectRatio} overflow="hidden" data-rendering="Video Component" bg="black">
					<Video isImgSrc={isImgSrc} videoId={videoId} isAspectRatio={isAspectRatio} isOverlay={isOverlay} {...rest}>
						{children}
					</Video>
				</AspectRatio>
				:
				<Box ref={moduleRef} w="100%" h="100%" bg="black" {...isShadow ? { boxShadow: { lg: "0px -10px 50px 0px #00000026" } } : {}} overflow="hidden" position="relative" display="flex" justifyContent="center" alignItems="center">
					<Video isImgSrc={isImgSrc} videoId={videoId} isAspectRatio={isAspectRatio} isOverlay={isOverlay} {...rest}>
						{children}
					</Video>
				</Box>
			}
		</>
	)
}

export default VideoComponent;
