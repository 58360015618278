import { AspectRatio, Box, Flex, Text } from "@chakra-ui/react";
import { LazyImage } from "~/foundation/Components/Image/LazyImage";
import { Headline } from "../../components/ui/headline";
import BundleIcon from "../../components/ui/bundle-icon";
import { pxToRem } from "../../theme/utils/mapPxToRem";
import { useSitecoreContext } from "~/foundation/Jss";
import { useColorThemeVariant } from "../../theme/ColorThemeContext";
import { type CardProps } from "./Card";
import { EventInfo } from "../../../_Base/components/eventInfo";


const groupHoverBeforeArrowStyle = {
	opacity: 1,
	transform: "translateX(8px)",
};

const EventCard = ({
	title,
	text,
	imageUrl,
	size = "small",
	date,
	numberOfLines,
	location,
	time,
	...rest
}: CardProps) => {
	let imageCrop = "&cw=590&ch=361";
	let aspectRatio = 582 / 353;
	if (size === "tall") {
		aspectRatio = 280 / 423;
		imageCrop = "&cw=289&ch=361";
	}
	const aspectRatioMobile = 253 / 423;
	const { sitecoreContext } = useSitecoreContext();
	const isRtl = sitecoreContext.custom.settings.isRtl;
	const { cardBG, listCardBG, cardFontColor, primaryColor } = useColorThemeVariant("white");

	function formatDate(input: Date | string): string {
		const date = typeof input === "string" ? new Date(input) : input;

		if (isNaN(date.getTime())) {
			throw new Error("Invalid date input");
		}

		const options: Intl.DateTimeFormatOptions = { year: 'numeric', month: 'long', day: 'numeric' };
		return date.toLocaleDateString('en-US', options);
	}

	return (
		<Box position="relative" overflow="hidden" w={"100%"}
			cursor={"pointer"}
			bgColor={cardBG}
			{...rest}>
			{imageUrl && <Flex width="calc(100% + 8px)" flex="0 0 auto" alignItems="flex-end" position="absolute" top={0} left={0} height="calc(100% + 8px)" zIndex={1} _before={{
				content: '""',
				position: "absolute",
				top: 0,
				left: 0,
				width: "100%",
				height: "100%",
				bg: "rgba(0,0,0,0.6)",
				zIndex: 2,
			}}>
				<AspectRatio width="100%" height="100%" ratio={[aspectRatioMobile, null, null, aspectRatio]} zIndex={1}>
					<LazyImage
						src={imageUrl + imageCrop}
						alt={title}
						width={"100%"}
						height={"calc(100% + 8px)"}
						objectFit={"cover"}
						objectPosition={"top"}
						transition={"all 0.3s ease"}
						opacity={0}
						transform={"translateY(-8px)"}
						zIndex={2}
						onLoad={(e) => {
							(e.target as HTMLImageElement).style.opacity = "1";
						}}
						_groupHover={{
							transform: "translateY(0)",
						}}
					/>
				</AspectRatio>
			</Flex>}
			<Flex height="100%" flexDir="column" position="relative" zIndex={2} p={pxToRem("24px")}>
				<Flex flex={1} flexDir="column" gap={pxToRem("16px")}>
					<EventInfo customColor={imageUrl ? listCardBG : primaryColor} date={date && formatDate(date)} time={time} location={location} />
					<Headline
						size="headline5"
						mb={pxToRem("8px")}
						color={imageUrl ? listCardBG : cardFontColor}
					>
						{title}
					</Headline>
					<Text
						size="smallBody"
						color={imageUrl ? listCardBG : cardFontColor}
						display={"-webkit-box"}
						sx={{
							WebkitLineClamp: numberOfLines ?? "none",
							WebkitBoxOrient: "vertical",
						}}
						overflow={"hidden"}
						w={size === "tall" ? "100%" : "70%"}
					>
						{text}
					</Text>
				</Flex>
				<BundleIcon
					name="button-arrow"
					fill={imageUrl ? listCardBG : primaryColor}
					size="lg"
					flex="0 0 auto"
					width={
						{
							base: pxToRem("16px"),
							md: pxToRem("28px"),
						}
					}
					height="auto"
					transition="all 0.3s ease"
					_groupHover={groupHoverBeforeArrowStyle}
					isRtl={isRtl}
				/>
			</Flex>
		</Box>
	)
};

export { EventCard };