import { pxToRem } from "../../Utils/mapPxToRem";

const header = {
	headerHeight: pxToRem("80px"),
}

const container = {
	max: pxToRem("2100px"),
	content: pxToRem("1092px"),
}

const sizes = {
	wfull: "100vw",
	hfull: "100vh",
	max: "max-content",
	min: "min-content",
	full: "100%",
	container,
	...header,
	moduleTitleStickyTop: `calc(${header.headerHeight} + ${pxToRem("20px")})`,
};

export default sizes;
