import { Flex, Heading, Box } from "@chakra-ui/react";
import { LinkButtonIcon } from "./UI/Button";
import { useSitecoreContext, type TextField, type LinkField } from "~/foundation/Jss";
import { sc } from "../Jss"
import sizes from "../Theme/variables/sizes";

type ModuleHeaderProps = {
	isEditable?: boolean;
	title?: TextField | {
		value: string;
	};
	titleSize?: "sm" | "lg";
	link?: LinkField | {
		value?: {
			href?: string;
			text?: string;
			target?: string;
		}
	};
	orientation?: "horizontal" | "vertical";
	isDarkBgTheme?: boolean;
	isSticky?: boolean;
}

export const ModuleHeader = ({ isEditable = true, title, titleSize = "sm", link, orientation = "horizontal", isDarkBgTheme = true, isSticky = false }: ModuleHeaderProps) => {
	const { sitecoreContext } = useSitecoreContext();

	return (
		(title?.value || link?.value?.href || sitecoreContext.pageEditing) && <Box w={orientation === "horizontal" ? "100%" : ["100%", null, null, "40%"]} flex={orientation === "horizontal" ? "1" : "0 0 auto"}>
			<Flex w="100%" flexDir={orientation === "horizontal" ? "row" : ["row", null, null, "column"]} gap="md" alignItems={orientation === "horizontal" ? "center" : ["center", null, null, "flex-start"]} justifyContent={orientation === "horizontal" ? "space-between" : ["space-between", null, null, "flex-start"]} {...orientation === "vertical" && isSticky && { position: ["static", null, null, "sticky"], top: sizes.moduleTitleStickyTop, zIndex: "level1" }}>
				{(title?.value || sitecoreContext.pageEditing) && <Heading as="h2" size={titleSize === "sm" ? "h5" : "h2"} color={isDarkBgTheme ? "primary.white" : "primary.dark"}>
					{isEditable ? <sc.Text field={title} /> : title?.value}
				</Heading>}
				{link?.value?.href && (!sitecoreContext.pageEditing || (sitecoreContext.pageEditing && !isEditable)) &&
					<LinkButtonIcon to={link?.value.href} title={link?.value.text} target={link?.value.target} size={"lg"} variant="unstyled" icon="chevron-left" iconPosition="after" isSidePadding={false} color={isDarkBgTheme ? "primary.white" : "primary.dark"}>{link?.value.text}</LinkButtonIcon>
				}
				{sitecoreContext.pageEditing && isEditable &&
					<LinkButtonIcon as="div" size={"lg"} variant="unstyled" icon="chevron-left" iconPosition="after" isSidePadding={false} color={isDarkBgTheme ? "primary.white" : "primary.dark"} sx={{
						a: {
							color: isDarkBgTheme ? "primary.white" : "primary.dark"
						}
					}}>
						<sc.Link field={link} />
					</LinkButtonIcon>
				}
			</Flex>
		</Box>
	)
}