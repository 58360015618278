import { Heading } from "../components/Heading";
import { Text } from "../components/Text";

export const getGlobalStyling = () => {
	return ({
		global: {
			html: {},
			body: {},
			".visually-hidden": {
				clip: "rect(0 0 0 0)",
				clipPath: "inset(50%)",
				height: "1px",
				overflow: "hidden",
				position: "absolute",
				whiteSpace: "nowrap",
				width: "1px",
			},
			"not('.page-editing')": {
				"*:focus-visible": {
					outline: ".375rem double black !important",
					boxShadow: "0 0 0 .25rem white",
					borderRadius: ".125rem",
				},
			},
			".richText": {
				"> div *:last-child,> *:last-child": {
					mb: "0",
				},
				"h1,h2,h3,h4,h5,h6,blockquote,p,ul,ol": {
					":first-of-type": {
						mt: "0",
					},
				},
				"h1,h2,h3,h4,h5,h6": {
					mb: "md",
				},
				"h1,h2,h3,h4": {
					...Heading().sizes?.h4,
				},
				"h5,h6": {
					...Heading().sizes?.h5,
				},
				p: {
					"> strong:first-of-type": {
						display: "block",
						fontSize: "1.5rem", // 24px
						marginBottom: ["md", null, null, "md"],
					},
					color: "primary.dark",
					...Text().sizes?.bodyL,
					mb: "md",
					strong: {
						fontWeight: "600",
					},
					em: {
						fontStyle: "italic",
					},
				},
				a: {
					textDecoration: "underline",
					cursor: "pointer",
					outline: "none",
					wordBreak: "break-word",
					_focusVisible: {
						outline: "1px dotted black",
						outlineOffset: "2px",
						outlineColor: "black",
						textDecoration: "underline dotted",
					},
					_hover: {
						outline: "none",
						textDecoration: "none",
					},
				},
				"ul, ol": {
					...Text().sizes?.bodyL,
					display: "block",
					w: "auto",
					mb: "md",
					ml: 0,
					mr: "lmd",
					"> li": {
						mb: "xs",
						strong: {
							fontWeight: "600",
						},
						em: {
							fontStyle: "italic",
						},
					},
				},
				ul: {
					listStyle: "disc",
				},
				ol: {
					listStyle: "arabic-indic",
				},
				strong: {
					fontWeight: "normal",
				},
				em: {
					fontStyle: "normal",
				},
			},
			".articleRichText": {
				figure: {
					width: "100%",
					marginTop: "md",
					marginBottom: "md",

					"a[href*='.jpg'],img": {
						margin: "none",
						width: "100%",
					},
					"&.alignleft:has(img)": {
						width: ["100%", null, null, "40% !important"],
						float: ["none", null, null, "right"],
						marginLeft: 0,
						marginRight: ["none", null, null, "lmd"],
						"+ p": {
							display: "block",
							width: ["100%", null, null, "auto !important"],
							// paddingLeft: ["0", null, null, "lmd"],
							marginLeft: 0,
							marginRight: 0,
						}
					},
					"&.alignright:has(img)": {
						width: ["100%", null, null, "40% !important"],
						float: ["none", null, null, "left"],
						marginLeft: ["none", null, null, "lmd"],
						marginRight: 0,
						"+ p": {
							display: "block",
							width: ["100%", null, null, "auto !important"],
							// paddingRight: ["0", null, null, "lmd"],
							marginLeft: 0,
							marginRight: 0,
						}
					},
				},
				figcaption: {
					marginTop: "md",
				},
				".gallery": {
					display: "flex",
					flexDirection: "row",
					flexWrap: "no-wrap",
					gap: "md",
					".gallery-item": {
						maxWidth: "50%",
					}
				},
				"p": {
					display: "inline-block",
					marginBottom: ["md", null, null, "lg"],

					"a[href*='.jpg']": {
						pointerEvents: "none",

						img: {
							margin: "none",
							width: "100%",
						}
					},

					br: {
						content: "''",
						display: "block",
						width: ["100%", null, null, "50%"],
						marginBottom: ["md", null, null, "lg"],
					},

					_odd: {
						"a[href*='.jpg'], img": {
							float: ["none", null, null, "right"],
							marginRight: ["none", null, null, "lmd"],
							marginLeft: "none",
							marginTop: "none",
						},
					},

					_even: {
						// Note to self:
						// might change to left later.
						float: ["none", null, null, "none"],
						marginLeft: ["none", null, null, "lmd"],
						marginRight: "none",
						marginTop: "none",
						// border: "10px solid red",
					},

					"a[href*='.jpg'], img": {
						display: ["block", null, null, "inline-block"],
						width: ["100%", null, null, "50%"],
						marginTop: "md",
						marginBottom: ["md", null, null, "lg"],

						_odd: {
							float: ["none", null, null, "right"],
							marginRight: ["none", null, null, "md"],
							marginLeft: "none",
						},

						_even: {
							float: ["none", null, null, "left"],
							marginLeft: ["none", null, null, "md"],
							marginRight: "none",
						},
					},
				},

				hr: {
					marginTop: "xs",
					marginBottom: "md",
				},

			},
		}
	})

};
