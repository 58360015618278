/* eslint-disable @typescript-eslint/no-unused-vars */
import { type ComponentStyleConfig } from "@chakra-ui/react";
import { getBaseButtonSizesStyles, getBaseButtonStyles, getButtonVariants } from "./Buttons";

export type LinkButtonVariant = keyof (ReturnType<typeof getButtonVariants>);

export const LinkButton = (isRtl: boolean): ComponentStyleConfig => ({
	baseStyle: getBaseButtonStyles(isRtl),
	sizes: getBaseButtonSizesStyles(isRtl),
	variants: getButtonVariants(isRtl),
	defaultProps: {
		size: "lg",
		variant: "dark",
	},
});