import { type GlobalDictionary } from "../../dictionary";
import { useTranslation } from "~/foundation/Dictionary";
//import { convertNumbersToAr } from "./convertNumbersToAr";

const getTimeUnitText = (singularUnit: string, twoUnit: string, pluralUnit: string, value: number) => {
	if (value === 1) {
		return singularUnit;
	}

	if (value === 2) {
		return twoUnit;
	}

	//return convertNumbersToAr(`${value} ${value > 10 ? singularUnit : pluralUnit}`);
	return `${value} ${value > 10 ? singularUnit : pluralUnit}`;
}

export const formatTime = (minutes: number): string => {
	const [t] = useTranslation<GlobalDictionary>();

	if (minutes < 1) {
		const seconds = Math.round(minutes * 60);
		return getTimeUnitText(t("time.second"), t("time.twoSeconds"), t("time.seconds"), seconds);
	}

	if (minutes < 60) {
		return getTimeUnitText(t("time.minute"), t("time.twoMinutes"), t("time.minutes"), minutes);
	}

	const hours = Math.floor(minutes / 60);
	const mins = minutes % 60;

	const hourText = getTimeUnitText(t("time.hour"), t("time.twoHours"), t("time.hours"), hours);
	const minuteText = getTimeUnitText(t("time.minute"), t("time.twoMinutes"), t("time.minutes"), mins);

	return `${hours !== 0 ? hourText : ''} ${mins !== 0 ? t("general.and") : ''} ${mins !== 0 ? minuteText : ''}`;
}