import { Icon, forwardRef, type IconProps } from "@chakra-ui/react";

export interface BundleIconProps extends IconProps {
	name: "arrow-left" | "checkmark" | "chevron-down" | "chevron-left" | "close" |
	"download" | "facebook" | "grid" | "menu" | "minus"
	| "play" | "plus" | "search" | "slider-horizontal" | "upload" | "whatsapp"
	| "x" | "youtube" | "qafilah-logo" | "quote" | "listen" | "pause";
	size?: string | (string | number | null)[] | number;
	isRtl?: boolean;
}

const BundleIcon = forwardRef<BundleIconProps, "svg">(
	({ name, isRtl, ...props }, ref) => {
		return (
			<Icon
				{...props}
				ref={ref}
				aria-hidden="true"
				transform={isRtl ? "scaleX(-1)" : ""}
			>
				<use xlinkHref={`#qafilah_icon_${name}`} />
			</Icon>
		);
	}
);
export default BundleIcon;
