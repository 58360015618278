import { mapPxToRem } from "../../Utils/mapPxToRem";

const spacings: Record<string, string> = {
	none: "0px",
	xxs: "5px",
	xs: "10px",
	sm: "15px",
	md: "20px",
	lmd: "30px",
	lg: "40px",
	sxl: "48px",
	xl: "60px",
	xxl: "80px",
};

const spacing = mapPxToRem(spacings);

export default spacing;
