/* eslint-disable @typescript-eslint/no-unused-vars */
import { type ComponentStyleConfig } from "@chakra-ui/react";
import { colors } from "../variables/colors";
import { pxToRem } from "../../Utils/mapPxToRem";

export const getButtonVariants = (isRtl = true, useHover = true) => ({
	"light": {
		bg: colors.primary.white,
		color: 'black',
		border: `1px solid ${colors.primary.white}`,
		_hover: useHover ? {
			bg: 'transparent',
			color: colors.primary.white,
		} : {},
	},
	"dark": {
		bg: colors.primary.dark,
		color: colors.primary.white,
		border: `1px solid ${colors.primary.dark}`,
		_hover: useHover ? {
			bg: 'transparent',
			color: colors.primary.dark,
		} : {},
	},
	"unstyled": {
		bg: 'transparent',
		color: 'inherit',
		boxShadow: 'none',
		_hover: useHover ? {
			bg: 'transparent',
		} : {},
	},
	"unstyled-w-hover-dark": {
		bg: 'transparent',
		color: colors.primary.dark,
		boxShadow: 'none',
		border: `1px solid transparent`,
		_hover: useHover ? {
			bg: 'transparent',
			color: colors.primary.dark,
			border: `1px solid ${colors.primary.dark}`,
		} : {},
	},
	"unstyled-w-hover-light": {
		bg: 'transparent',
		color: colors.primary.white,
		boxShadow: 'none',
		border: `1px solid transparent`,
		_hover: useHover ? {
			bg: 'transparent',
			color: colors.primary.white,
			border: `1px solid ${colors.primary.white}`,
		} : {},
	},
	"bordered-dark": {
		bg: 'transparent',
		color: colors.primary.dark,
		border: `1px solid ${colors.primary.dark}`,
		_hover: useHover ? {
			bg: colors.primary.dark,
			color: colors.primary.white,
		} : {},
	},
	"bordered-light": {
		bg: 'transparent',
		color: colors.primary.white,
		border: `1px solid ${colors.primary.white}`,
		_hover: useHover ? {
			bg: colors.primary.white,
			color: colors.primary.dark,
		} : {},
	},
});

export const getBaseButtonStyles = (isRtl: boolean) => ({
	fontFamily: 'body',
	fontWeight: '400',
	_focusVisible: {
		outline: ".375rem double black !important",
		boxShadow: "0 0 0 .25rem white",
		borderRadius: ".125rem",
	},
	transition: 'background-color 0.2s, color 0.2s, border-color 0.2s',
	_disabled: {
		opacity: 0.3,
		pointerEvents: 'none',
		cursor: 'not-allowed',
	},
})

export const getBaseButtonSizesStyles = (isRtl: boolean) => ({
	lg: {
		fontSize: 'sm',
		px: 'md',
		py: "xs",
	},
	md: {
		fontSize: 'sm',
		px: 'md',
		py: "xxs",
	},
	page: {
		fontSize: 'xxs',
		lineHeight: 'full',
		w: pxToRem("32px"),
		h: pxToRem("32px"),
	},
})

export type ButtonVariant = keyof (ReturnType<typeof getButtonVariants>);

export const Button = (isRtl: boolean): ComponentStyleConfig => ({
	baseStyle: getBaseButtonStyles(isRtl),
	sizes: getBaseButtonSizesStyles(isRtl),
	variants: getButtonVariants(isRtl),
});