import { useEffect, useState } from "react";
import { type ISearchResultItemModel } from "~/feature/Search/generated-types";
import { Container, Grid, GridItem, useBreakpointValue } from "@chakra-ui/react";
import { Card, type CardProps, type CardSizes } from "./Card";
import { OneRowCarousel } from "../Carousel/OneRowCarousel";
import { pxToRem } from "../../theme/utils/mapPxToRem";
import { type Optional } from "utility-types";

type EventCardProps = {
	time?: string,
	location?: string,
}

export type OneRowCardsProps = {
	results: Array<Optional<ISearchResultItemModel> & EventCardProps> | undefined,
};

const OneRowCards = ({ results }: OneRowCardsProps) => {
	const [cards, setCards] = useState<CardProps[]>([]);
	const [isSlider, setIsSlider] = useState(false);
	const isMobile = useBreakpointValue({ base: true, lg: false });

	useEffect(() => {
		if (results) {
			const mapped: CardProps[] = results.map((item, index) => {
				return {
					title: item.title,
					text: item.description,
					imageUrl: item.smallImage?.url,
					size: index === 0 ? "medium" : "small",
					url: item.url,
					date: item.date,
					time: item.time,
					location: item.location,
					cardType: item.contentType?.toLowerCase() === "event" ? "event" : "article",
				}
			});
			if (mapped.length > 4) {
				setIsSlider(true);
			}
			setCards(mapped)
		}
	}, [results]);


	const renderCards = () => {
		return (
			<Grid
				templateColumns="repeat(12, 1fr)"
				gap={{ base: "1rem", lg: `${pxToRem("80px")} ${pxToRem("20px")}` }}
				padding={{ base: "1rem", lg: "2rem 0" }}
				data-container="cards"
			>
				{cards.map((card, index) => {
					let colSpan = 6;
					let size: CardSizes = "small";
					if (card.cardType === "article") {
						if (cards.length === 2) {
							colSpan = index === 0 ? 7 : 5;
							size = "medium";
						} else if (cards.length === 3) {
							colSpan = index === 0 ? 6 : 3;
							size = index === 0 ? "medium" : "small";
						} else if (cards.length === 4) {
							colSpan = 3;
							size = index === 0 ? "tall" : "small";
						}
					} else if (card.cardType === "event") {
						if (cards.length === 2) {
							colSpan = 6;
							size = "medium";
						} else if (cards.length === 3) {
							colSpan = index === 0 ? 6 : 3;
							size = index === 0 ? "medium" : "tall";
						} else if (cards.length === 4) {
							colSpan = 3;
							size = "tall";
						}
					}

					return (
						<GridItem
							key={index}
							colSpan={{
								base: 6,
								lg: colSpan,
							}}
						>
							<Card
								title={card.title}
								text={card.text}
								imageUrl={card.imageUrl}
								date={card.date}
								size={size}
								url={card.url}
								numberOfLines={3}
								cardType={card.cardType}
								{...card.cardType === "event" && {
									date: card.date,
									time: card.time,
									location: card.location,
									height: [423, null, null, index === 0 || cards.length === 2 ? 353 : 423]
								}}
							/>
						</GridItem>
					)
				})}
			</Grid>
		)
	};

	return (
		<Container>
			{(isSlider || isMobile) && cards?.length ? <OneRowCarousel cards={cards} /> : null}
			{!isSlider && !isMobile && cards?.length ? <>{renderCards()}</> : null}
		</Container>
	);
};

export { OneRowCards };