import { useState, useEffect } from "react";
import { Box, Flex, Container, Button } from "@chakra-ui/react";
import {
	Carousel,
	CarouselA11y,
	type CarouselClass,
	CarouselNavigation,
	CarouselScrollbar,
} from "../Carousel/carousel";
import BundleIcon from "../bundleIcon";
import { useTranslation } from "~/foundation/Dictionary";
import { type GlobalDictionary } from "../../../dictionary";

type ListCarouselProps = {
	children: React.ReactNode,
	id: string,
	isDarkBg?: boolean,
	slidesPerView?: number,
	breakpoints?: Record<string, { slidesPerView: number, spaceBetween?: number, speed?: number }>,
	numberOfItems: number,
	maxItems: number,
}

const ListCarousel = ({ children, id, isDarkBg = false, slidesPerView = 1, breakpoints, numberOfItems, maxItems, ...rest }: ListCarouselProps) => {
	const [swiperInstance, setSwiperInstance] = useState<CarouselClass | null>(null);
	const [isClient, setIsClient] = useState(false);
	const [t] = useTranslation<GlobalDictionary>();

	useEffect(() => {
		setIsClient(true);
	}, []);

	return (
		<Flex flexDir="column" gap={["lmd", null, null, "xl"]} id={id}>
			<Container px={["none", null, null, "none"]} overflowX="hidden">
				{isClient &&
					<Carousel
						onSwiper={setSwiperInstance}
						modules={[CarouselA11y, CarouselNavigation, CarouselScrollbar]}
						keyboard={{ enabled: true }}
						slidesPerView={slidesPerView}
						breakpoints={breakpoints}
						spaceBetween={0}
						{...numberOfItems > maxItems && {
							scrollbar: {
								el: `#${id} .swiper-scrollbar`,
								draggable: true,
								enabled: numberOfItems > maxItems,
							}
						}}
						sx={{
							width: "100%",
						}}
						//overflowX="auto"
						pl={["sm!important", null, null, "lmd!important"]}
						{...rest}>
						{children}
					</Carousel>
				}
			</Container>
			<Container display={["none", null, numberOfItems > maxItems ? "block" : "none"]}>
				<Flex gap="md">
					<Button transform="scaleX(-1)" flex="0 0 auto" title={t("button.carousel.previous")} aria-label={t("button.carousel.previous")} onClick={() => swiperInstance?.slidePrev()} px="none">
						<BundleIcon name="chevron-left" size="sxl" fill={isDarkBg ? "primary.white" : "primary.dark"} />
					</Button>
					<Button flex="0 0 auto" title={t("button.carousel.next")} aria-label={t("button.carousel.next")} onClick={() => swiperInstance?.slideNext()} px="none">
						<BundleIcon name="chevron-left" size="sxl" fill={isDarkBg ? "primary.white" : "primary.dark"} />
					</Button>
					<Flex flex="1" alignItems="center">
						<Box
							className="swiper-scrollbar"
							sx={{
								position: "relative !important",
								cursor: "grab",
								background: "transparent !important",
								width: "100% !important",
								height: "100% !important",
								top: "50% !important",
								left: "0 !important",
								right: "0 !important",
								transform: "translateY(-50%) !important",
								_before: {
									content: '""',
									position: "absolute",
									top: 'calc(50% - 0.5px)',
									left: 0,
									right: 0,
									bottom: 0,
									background: isDarkBg ? "primary.white" : "primary.dark",
									height: "1px",
									opacity: 0.3,
									borderRadius: "10px",
								},
								".swiper-scrollbar-drag": {
									background: "transparent !important",
									position: "relative !important",
									_before: {
										content: '""',
										position: "absolute",
										top: 'calc(50% - 0.5px)',
										left: 0,
										right: 0,
										bottom: 0,
										background: isDarkBg ? "primary.white" : "primary.dark",
										height: "1px",
										borderRadius: "10px",
									}
								},
							}} />
					</Flex>
				</Flex>
			</Container>
		</Flex>
	);
};

export default ListCarousel;