import { transparentize } from "polished"

const primary = {
	white: '#FFF',
	black: '#000',
	dark: "rgb(17, 17, 17)",
}

const secondary = {
	darkGreen: "#143630",
	goldenYellow: "#F5CB5C"
}

const topics = {
	lightBlue: "#B4C5E4",
	amethystPurple: "#5F45B6",
	orange: "#F39B41",
	burgundy: "#5C2751",
	darkTeal: "#08605F",
}

const tags = {
	lifeBg: "#e6efef",
	lifeText: "#064d4c",
	sciencesBg: "#efe9ee",
	sciencesText: "#4b2e83",
	thoughtsBg: "#fdebd9",
	thoughtsText: "#794e20",
	artsBg: "#efecf8",
	artsText: "#4c3792",
	literatureBg: "#e1e8f4",
	literatureText: "#484f5b",
}

const support = {
	grayBlue: "#484F5B",
}

const tints = {
	black: {
		70: transparentize(0.3, primary.black),
		60: transparentize(0.4, primary.black),
		50: transparentize(0.5, primary.black),
		30: transparentize(0.7, primary.black),
		10: transparentize(0.9, primary.black),
		5: transparentize(0.95, primary.black),
	},
	white: {
		70: transparentize(0.3, primary.white),
		60: transparentize(0.4, primary.white),
		50: transparentize(0.5, primary.white),
		40: transparentize(0.6, primary.white),
		30: transparentize(0.7, primary.white),
		20: transparentize(0.8, primary.white),
		10: transparentize(0.9, primary.white),
		5: transparentize(0.95, primary.white),
	},
	dark: {
		80: transparentize(0.2, primary.dark),
		70: transparentize(0.3, primary.dark),
		60: transparentize(0.4, primary.dark),
		50: transparentize(0.5, primary.dark),
		40: transparentize(0.6, primary.dark),
		30: transparentize(0.7, primary.dark),
		20: transparentize(0.8, primary.dark),
		10: transparentize(0.9, primary.dark),
		5: transparentize(0.95, primary.dark),
	}
}

const overlays = {
	generic: `
		linear-gradient(
			180deg,
			rgba(17, 17, 17, 0.8) 0%,
			rgba(17, 17, 17, 0.3) 10%,
			rgba(17, 17, 17, 0.3) 25%,
			rgba(17, 17, 17, 0.5) 35%,
			rgba(17, 17, 17, 0.7) 50%,
			rgba(17, 17, 17, 0.8) 100%
		)
	`,
	sliderAuthorCard: `
		linear-gradient(
			180deg,  
			rgba(17, 17, 17, 0) 0%,
			rgba(17, 17, 17, 0.3) 40%,
			rgba(17, 17, 17, 0.9) 80%
		)
	`,
	podcastCard: `
		linear-gradient(
			180deg,  
			rgba(17, 17, 17, 0.2) -20%,
			rgba(17, 17, 17, 0.9) 80%
		)
	`,
}

export const colors = {
	primary,
	secondary,
	topics,
	support,
	tints,
	tags,
	overlays,
}