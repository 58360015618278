import { type SitecoreContextValue } from "~/foundation/Jss";
import { type TopicCategoryPage } from "../../generated-types";
import { type TopicTag } from "../Components/UI/Tag";
import { type ColorThemeId } from "../Theme";
import { getColorTheme } from "../Theme/getColorTheme";
import { useColorTheme } from "../Theme/ColorThemeContext";

export const isDarkTheme = (sitecoreContext: SitecoreContextValue<unknown, unknown>) => {
	const templateName = sitecoreContext.route?.templateName?.toLocaleLowerCase();

	let isDark = templateName === "photojournal article page"
		|| templateName === "video article page"
		|| templateName === "podcast article page"
		|| templateName === "issue"
		|| templateName === "issue section";

	if (templateName === "topic category page"
		|| templateName === "topic section page"
		|| templateName === "topic sub category page"
	) {
		const fields = sitecoreContext.route?.fields as TopicCategoryPage;

		if (fields?.category) {
			const theme = (fields?.category as unknown as TopicTag)?.fields?.theme?.value as ColorThemeId;
			if (theme) {
				const { isDarkBg } = getColorTheme(theme);
				isDark = !isDarkBg;
			}
		}
	} else if (templateName !== "issue section") {
		const { isDarkBg, name } = useColorTheme();
		console.log(name, 'name')
		isDark = !isDarkBg;
	}

	return isDark;
}