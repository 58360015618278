import { Box, Text } from "@chakra-ui/react";
import { type BreadcrumbItem } from "~/foundation/SitecoreContent/generated-types";
import { Link } from "../Link";
import { useTranslation } from "~/foundation/Dictionary";
import { type GlobalDictionary } from "../../../dictionary";

const Breadcrumbs = ({
	breadcrumbItems,
	isDark
}: {
	breadcrumbItems: BreadcrumbItem[],
	isDark?: boolean
}) => {
	const [t] = useTranslation<GlobalDictionary>();
	return (
		<nav aria-label="Breadcrumb">
			<Box
				as="ol"
				sx={{
					listStyle: "none",
					display: "flex",
				}}
			>
				<li>
					<Link to={"/"}>
						<Text
							sx={{
								color: !isDark ? "tints.white.60" : "tints.black.50",
								_hover: {
									textDecoration: "underline",
								}
							}}
							size={"bodyS"}
							transition={"all 0.2s ease-in-out"}
						>
							{t("general.labels.homeLabel")}
						</Text>

						<Box sx={{
							color: !isDark ? "tints.white.60" : "tints.black.50",
							padding: "0 .5rem"
						}}
						>/</Box>
					</Link>
				</li>
				{breadcrumbItems.map((item, index) => {
					// If last item, don't show link
					if (index === breadcrumbItems.length - 1) {
						return (
							<Box
								key={`breadcrumb-${index}`}
								sx={{
									display: "flex",
									alignItems: "center"
								}}
								as="li"
							>
								<Text
									size={"bodyS"}
									sx={{
										color: !isDark ? "primary.white" : "primary.dark",
									}}
								>
									{item.title || "Untitled"}
								</Text>
							</Box>
						);
					}
					return (
						<li key={`breadcrumb-${index}`}>
							<Link to={item.url}>
								<Text
									sx={{
										color: !isDark ? "tints.white.60" : "tints.black.50",
										_hover: {
											textDecoration: "underline",
										}
									}}
									size={"bodyS"}
									transition={"all 0.2s ease-in-out"}
								>
									{item.title || "Untitled"}
								</Text>
								{breadcrumbItems.length > (index + 1) ?
									<Box sx={{
										color: !isDark ? "primary.white" : "primary.black",
										padding: "0 .5rem"
									}}
									>/</Box> : null}
							</Link>
						</li>
					);
				})}
			</Box>
		</nav>
	)
}

export { Breadcrumbs }