import { type FC } from "react";
import { useSitecoreContext } from "@sitecore-jss/sitecore-jss-react"; // eslint-disable-line no-restricted-imports
import RoutableSitecoreLink, { type LinkProps } from "./RoutableSitecoreLink";

const LinkWrapper: FC<LinkProps> = (props) => {
	const { sitecoreContext } = useSitecoreContext();

	if (!props?.field && !sitecoreContext.pageEditing) {
		return <></>;
	}

	return <RoutableSitecoreLink {...props} />;
};

export default LinkWrapper;