import { type FC } from "react";
import { Box, Container, Heading } from "@chakra-ui/react";
import { Breadcrumbs } from "../Beadcrumbs/Breadcrumbs";
import { type BreadcrumbItem } from "~/foundation/SitecoreContent/generated-types";
import { type MainNavigationItemModel } from "~/feature/Navigation/generated-types";
import { LinkButton } from "../UI/Button";
import { useLocation } from "react-router-dom";
import { type ColorThemeId } from "../../Theme";
import { useColorTheme } from "../../../_Base/Theme/ColorThemeContext";
import { getColorTheme } from "../../Theme/getColorTheme";

type PageHeaderProps = {
	headline: string;
	trumpet?: string;
	breadcrumbItems: BreadcrumbItem[];
	menuItems?: MainNavigationItemModel[] | undefined;
	bgColor?: string;
	theme?: ColorThemeId;
};

const PageHeader: FC<PageHeaderProps> = ({
	headline,
	breadcrumbItems,
	menuItems,
	theme
}) => {
	const location = useLocation();
	const { isDarkBg, colors } = theme ? getColorTheme(theme) : useColorTheme();
	const { primaryColor } = colors;
	const parentItem = breadcrumbItems.length > 1 ? breadcrumbItems[breadcrumbItems.length - 2] : null;

	const getNavItemVariant = (isActive: boolean, isDarkBg: boolean) => {
		if (isActive) {
			return isDarkBg ? "light" : "dark";
		} else {
			return isDarkBg ? "unstyled-w-hover-light" : "unstyled-w-hover-dark";
		}
	};

	return (
		<Box
			bg={primaryColor}
			pt={"xxl"}
		>
			<Container>
				<Box
					py={"md"}
				>
					<Breadcrumbs breadcrumbItems={breadcrumbItems} isDark={!isDarkBg} />
				</Box>

				{parentItem ?
					<Box
						display={"flex"}
						justifyContent={"center"}
					>
						<LinkButton
							w={"fit-content"}
							to={parentItem.url}
							size={"md"}
							variant={isDarkBg ? "bordered-light" : "bordered-dark"}
						>
							{parentItem?.title}
						</LinkButton>
					</Box>
					: null
				}
				{headline && <Box
					pb={["lg", null, null, "xxl"]}
					pt={["md", null, null, "lg"]}
					px={["md", null, null, "lg"]}
				>
					<Heading
						as="h1"
						size="h1"
						color={isDarkBg ? "white" : "black"}
						textAlign={"center"}
					>
						{headline}
					</Heading>
				</Box>}
				{
					menuItems && menuItems.length > 0 ? (
						<Box
							display={"flex"}
							justifyContent={"center"}
							flexWrap={["nowrap", null, null, "wrap"]}
							gap={"md"}
							pb={["xxs", null, null, "xxl"]}
							overflowX={["auto", null, null, "visible"]}
							mx={["-16px", null, null, 0]}
							px={["16px", null, null, 0]}
							sx={{
								'&::-webkit-scrollbar': {
									display: 'none'
								},
								scrollbarWidth: 'none',
								msOverflowStyle: 'none',
								width: ["calc(100% + 32px)", null, null, "100%"]
							}}
						>
							{menuItems.map((item, index) => (
								<LinkButton
									to={item.url}
									size={"lg"}
									variant={getNavItemVariant(location.pathname === item.url, isDarkBg)}
									key={index}
									sx={{
										backgroundColor: "tints.black.10",
										whiteSpace: "nowrap",
										flex: "0 0 auto"
									}}
								>
									{item.title ?? 'title missing'}
								</LinkButton>
							))}
						</Box>
					) : null
				}
			</Container>
		</Box>
	);
};

export { PageHeader };

